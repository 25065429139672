import React, { useEffect, useRef, useState } from 'react';
import TableChart from '../../../components/tables/Tablechart';
import AddIcon from '@mui/icons-material/Add';
import { Service_Api } from '../../../services/Api';
import { Salepoint } from '../../../services/object/Salepoint';
import { Button, Tab, Tabs } from '@mui/material';
import ModalFormComponent from '../../../components/modal/ModalFormComponent';
import { Univers } from '../../../services/object/Univers';
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import lawPopupChildrens from './LawPopupChildrens';
import appPopupChildrens from './AppPopupChildrens';
import rolePopupChildrens from './RolePopupChildrens';
import Alert, { AlertProps } from '../../../components/alert/Alert';
import { displayFormErrors } from '../../../services/tools/errorForm';
import Notification, { NotificationProps } from '../../../components/notification/Notification';
import { Law } from '../../../services/object/Law';
import { Job } from '../../../services/object/Job';
import { Role } from '../../../services/object/Role';
import { App } from '../../../services/object/App';
import style from './law.module.scss';
import LawTinyChildPopup from './LawTinyPopupChild';
import TinyModalComponent from '../../../components/tinyModal/TinyModalComponent';

function LawPage() {
    const [dataLaw, setDataLaw] = useState<{law : Law, job: Job[], role:Role[], app:App}[]>([]);
    const [dataRole, setDataRole] = useState<{role:Role, app:App}[]>([]);
    const [dataApp, setDataApp] = useState<App[]>([]);
    const [ToJob, setToJob] = useState<Job[]>([]);
    const [ToRole, setTORole] = useState<Role[]>([]);
    const [exceptionNumber, setExceptionNumber] = useState<number>(0);
    const [TOSalepoint, setTOSalepoint] = useState<Salepoint[]>([]);
    const [TOUnivers, setTOUnivers] = useState<Univers[]>([]);
    const [TOapp, setTOApp] = useState<App[]>([]);
    const [arrayUniversLabel, setArrayUniversLabel] = useState<[string[]]>();
    const [jobLabel, setJobLabel] = useState<string[]>([]);
    const [arrayJobLabel, setArrayJobLabel] = useState<[string[]]>();
    const [roleLabel, setRoleLabel] = useState<string[]>([]);
    const [arraySalepointLabel, setArraySalepointLabel] = useState<[string[]]>();
    const [rows, setRows] = useState([{}]);
    const [modalOpenRole, setModalOpenRole] = useState(false);
    const [modalOpenApp, setModalOpenApp] = useState(false);
    const [modalOpenLaw, setModalOpenLaw] = useState(false);
    const [tinyModalOpen, setTinyModalOpen] = useState(false);
    const [law, setLaw] = useState<Law>();
    const [app, setApp] = useState<App>();
    const [role, setRole] = useState<Role>();
    const [popupChildrenLaw, setPopupChildrenLaw] = useState<JSX.Element[]>();
    const [popupChildrenApp, setPopupChildrenApp] = useState<JSX.Element[]>();
    const [popupChildrenRole, setPopupChildrenRole] = useState<JSX.Element[]>();
    const [tinyPopupChild, setTinyPopupChil] = useState<JSX.Element>();
    const [displayAlert, setDisplayAlert] = useState<AlertProps>();
    const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
    const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
    const formRefRole = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const formRefLaw = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const formRefApp = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const [valueTabs, setValueTabs] = React.useState(0);
    const [columns, setColumns] = React.useState<GridColDef<any>[]>([]);

    const Api = Service_Api();
    
    /* hydrate data onload */
    useEffect(() => {
        fecthData()
    }, []);
  
    const fecthData = async() => {
        //get all salepoint (for row data)
        handleChangeTabs(null, 0);
        //get all univers (to hydrate popup's radio input)
        let response = await Api.get("job/");
        setToJob(response?.data.map(
            (job:Salepoint)=> job
        ))
        response = await Api.get("app/");
        setTOApp(response?.data.map(
            (app:App)=> app
        ))
        response = await Api.get("role/");
        setTORole(response?.data.map(
            ( objectRole :{role:Role, app:App})=> objectRole.role
        ))
        response = await Api.get("salepoint/");
        setTOSalepoint(response?.data)

        response = await Api.get("univers/");
        setTOUnivers(response?.data)
    }

    /* end hydrate data */

    //Create add data in row when const data change
    useEffect(() => {
        if(valueTabs === 0)
            setRows(dataLaw.map(
                (lawObject : {law : Law, job: Job[], role:Role[], app:App}) => {
                    return  { 
                        "id": lawObject.law.uid,
                        "JRA_law.name": lawObject.law.name,
                        "JRA_law.description": lawObject.law.description,
                        "JRA_job.name": lawObject.job?.map((job) => {return job.name}),
                        "JRA_role.name": lawObject.role?.map((role) => {return role.name}),
                        "JRA_app.name": lawObject.app.name,
                    }
                }
            ));
    }, [dataLaw]);
    useEffect(() => {
        if(valueTabs === 1)
            setRows(dataApp.map(
                (app:App) => {
                    return  { 
                        "id": app.uid,
                        "JRA_app.name": app.name,
                        "JRA_app.url": app.url,
                        "JRA_app.isJraApp": app.isJraApp
                    }
                }
            ));
    }, [dataApp]);
    useEffect(() => {
        if(valueTabs === 2)
            setRows(dataRole.map(
                (roleObject : {role:Role, app:App}) => {
                    return  { 
                        "id": roleObject.role.uid,
                        "JRA_role.name": roleObject.role.name,
                        "JRA_app.name": roleObject.app.name,
                    }
                }
            ));
    }, [dataRole]);

    const getLaw = async(lawUid:string) => {
        //get salepoint by uid
        const response = await Api.get("law/", "uid="+lawUid);
        //set current salepoint (to hydrate popup's input)
        setLaw({
            uid: response?.data.law.uid,
            name: response?.data.law.name,
            description: response?.data.law.description,
            appUid: response?.data.law.appUid,
            roleUid: response?.data.law.roleUid
        });
        setRoleLabel(response?.data.role?.map((role:Role)=>(role.name)) ?? []);
        setJobLabel(response?.data.job?.map((job:Job)=>(job.name)) ?? []);
        setModalOpenLaw(true);
    }
    const getApp = async(appUid:string) => {
        //get salepoint by uid
        let response = await Api.get("app/", "uid="+appUid);
        //set current salepoint (to hydrate popup's input)

        setApp({
            uid: response?.data.uid,
            name: response?.data.name,
            url: response?.data.url,
            photo: response?.data.photo,
            isJraApp: response?.data.isJraApp
        });
        
        response = await Api.get("app/getAllJobSalepointAssoByApp", "appUid="+appUid);
        let allValueJob: [string[]]= [[]];
        let allValueSalepoint: [string[]]= [[]];
        if(response?.success){
            response?.data.map((Telem:{TjobUid : [string], TsalepointUid: [string]}, index:number)=>{
                let TJobName = ToJob.filter((job : Job) => { 
                    return Telem.TjobUid.includes(job.uid)
                }).map((job :Job) => {return job.name})
                if(index > 0){
                    allValueJob.push(TJobName)
                }else{
                    allValueJob = [TJobName]
                }
                let TSalepointName = TOSalepoint.filter((salepoint : Salepoint) => { 
                    return Telem.TsalepointUid.includes(salepoint.uid)
                }).map((salepoint :Salepoint) => {return salepoint.name})
                if(index > 0){
                    allValueSalepoint.push(TSalepointName)
                }else{
                    allValueSalepoint = [TSalepointName]
                }
                setExceptionNumber(index+1)
            })
            setArrayUniversLabel(undefined);
        }
        if(allValueSalepoint)
            setArraySalepointLabel(allValueSalepoint);
        if(allValueJob)
            setArrayJobLabel(allValueJob);
       setModalOpenApp(true);
    }
    const getRole = async(roleUid:string) => {
        //get salepoint by uid
        const response = await Api.get("role/", "uid="+roleUid);
        //set current salepoint (to hydrate popup's input)
        setRole({
            uid: response?.data.role.uid,
            name: response?.data.role.name,
            appUid: response?.data.role.appUid
       });
       setModalOpenRole(true);
    }
    
    //event click on row
    const onRowClick = (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
        //get current row id (= current salepoint id)
        let uid = params.id as string
        //get current salepoint
        switch(valueTabs){
            case 0:
                getLaw(uid)
                break;
            case 1:
                getApp(uid)
                break;
            case 2:
                getRole(uid)
                break;
        }
    }
    //event click on card
    const onCardClick = (uid:string) => {
        //get current row id (= current salepoint id)
        //get current salepoint
        switch(valueTabs){
            case 0:
                getLaw(uid)
                break;
            case 1:
                getApp(uid)
                break;
            case 2:
                getRole(uid)
                break;
        }
    }
    //event click on icon delete
    const deleteLine = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams, valueTabs : number) =>{
        //desable onRowClick event
        event.preventDefault();
        event.stopPropagation();
        setDisplayAlert({
            open : true,
            title : "Suppression",
            contentText : "Êtes-vous sûr de vouloir supprimer cet item ?",
            contentButtonSuccess : "Oui",
            contentButtonAbord : "Non",
            onSubmitSuccess:  async () => {
                //get current row id (= current salepoint id)
                let uid = params.id as string
                let response;
                let newData;
                let object = "Le droit";
                switch(valueTabs){
                    case 0:
                        response = await Api.del("law/", {uid : uid});
                        //reload table data
                        setDataLaw((newData) => newData.filter(dataObject => dataObject.law.uid !== uid))
                        object = "Le droit";
                        break;
                    case 1:
                        response = await Api.del("app/", {uid : uid});
                        //reload table data
                        newData =  dataApp.filter((app:App) => app.uid !== uid)
                        setDataApp((newData) => newData.filter((app:App) => app.uid !== uid))
                        setTOApp((newData) => newData.filter((app:App) => app.uid !== uid))
                        object = "L'app";
                        break;
                    case 2:
                        response = await Api.del("role/", {uid : uid});
                        //reload table data
                        setDataRole((newData) => newData.filter((objectRole:{role:Role, app:App}) => objectRole.role.uid !== uid))
                        setTORole((newData) => newData.filter((role:Role) => role.uid !== uid))
                        object = "Le rôle";
                        break;
                }
                if(response?.success){
                    setDisplayNotif({
                        open : true,
                        contentText : object+" a été supprimée",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }
                setDisplayAlert(undefined)
            },
            onSubmitAbord:  () => (setDisplayAlert(undefined))
        })
        
    }

    /* popup */
    const resetPopup = () => {
        setLaw(undefined)
        setApp(undefined)
        setRole(undefined)
        setJobLabel([])
        setRoleLabel([])
        setErrorMessages({})
        setArrayJobLabel([[]])
        setArrayUniversLabel([[]])
        setArraySalepointLabel([[]])
        setExceptionNumber(0)
    }
    /* popup */

    //reset children data when salepoint change
    useEffect(() => {
        let children = lawPopupChildrens({  errorMessages, 
                                            formRefLaw, 
                                            law, 
                                            ToJob,
                                            jobLabel,
                                            setJobLabel,
                                            handleFormLawChange,
                                            TOapp,
                                            ToRole,
                                            roleLabel,
                                            setRoleLabel});
        setPopupChildrenLaw(children)
    }, [law, jobLabel, roleLabel, TOapp, ToRole, errorMessages]);
    
    //reset children data when salepoint change
    useEffect(() => {
        console.log(arraySalepointLabel)
        console.log(arrayJobLabel)

        let children = appPopupChildrens({  errorMessages, 
                                            formRefApp, 
                                            app,  
                                            ToJob,
                                            arrayJobLabel, 
                                            TOSalepoint,
                                            arraySalepointLabel,
                                            setArraySalepointLabel,
                                            setArrayJobLabel,
                                            TOUnivers,
                                            arrayUniversLabel,
                                            setArrayUniversLabel,
                                            handleFormAppChange,
                                            exceptionNumber, 
                                            setExceptionNumber
                                        });
        setPopupChildrenApp(children)
    }, [app, ToJob, TOSalepoint, TOUnivers, arrayJobLabel, arraySalepointLabel, arrayUniversLabel, exceptionNumber, errorMessages]);

    //reset children data when salepoint change
    useEffect(() => {
        let children = rolePopupChildrens({ errorMessages, 
                                            formRefRole, 
                                            role, 
                                            handleFormRoleChange,
                                            TOapp});
        setPopupChildrenRole(children)
    }, [role, TOapp, errorMessages]);

    //reset children data when salepoint change

    //event change on first form
    const handleFormLawChange = (property: string, value: string) => {
        //replace old property in current salepoint (input data)
        setLaw((lawValue : any) => ({
        ...lawValue,
        [property]: value,
      }));
    };
    //event change on first form
    const handleFormAppChange = (property: string, value: string) => {
        //replace old property in current salepoint (input data)
        setApp((appValue : any) => ({
        ...appValue,
        [property]: value,
      }));
    };
    //event change on first form
    const handleFormRoleChange = (property: string, value: string) => {
        //replace old property in current salepoint (input data)
        setRole((roleValue : any) => ({
        ...roleValue,
        [property]: value,
      }));
    };

    //event on click button create
    const handleOpenModalLaw = () => {
        setModalOpenLaw(true);
        setDisplayAlert(undefined);
        //reset input's data
    };
    //event on click button create
    const handleOpenModalRole = () => {
        setModalOpenRole(true);
        setDisplayAlert(undefined);
        //reset input's data
    };
    //event on click button create
    const handleOpenModalApp = () => {
        setModalOpenApp(true);
        setDisplayAlert(undefined);
        //reset input's data
    };
    
    //event on click icon close
    const handleCloseModalLaw = () => {
        setModalOpenLaw(false);
        //reset input's data
        resetPopup();
    };

    //event on click icon close
    const handleCloseModalRole = () => {
        setModalOpenRole(false);
        //reset input's data
        resetPopup();
    };

    //event on click icon close
    const handleCloseModalApp = () => {
        setModalOpenApp(false);
        //reset input's data
        resetPopup();
    };

    //event on click button create
    const handleOpenTinyModal = (law:Law) => {
        let child = LawTinyChildPopup({handleButtonAddLawUser, handleCloseTinyModal, law});
        setTinyPopupChil(child)
        setTinyModalOpen(true);
        //reset input's data

    }
    //event on click icon close
    const handleCloseTinyModal = () => {
        setTinyModalOpen(false);
        handleCloseModalLaw()
        //reset input's data
    };

    const handleButtonAddLawUser = async (law :Law) => {
        if(law.uid){
            await Api.post("law/addAssoUserByJob", {lawUid: law.uid, TjobUid: ToJob.filter((job)=> jobLabel.includes(job.name)).map((job)=>job.uid)}) 
            await Api.post("law/addAssoUserByRole", {lawUid: law.uid, TroleUid: ToRole.filter((role)=> roleLabel.includes(role.name) && law.appUid === role.appUid).map((role)=>role.uid)}) 
            handleCloseTinyModal()
        }
    }
    //event on salepoint's form
    const formEventLaw = async ()=>{
        let error = displayFormErrors(formRefLaw);
        if(Object.keys(error).length === 0){
            //if current salepoint has uid it exist so put
            let newLaw: {
                law : Law
                job : Job[]
                role : Role[]
                app : App
            };

            if(law?.uid){
                await Api.post("law/addAssoJob", {lawUid: law.uid, TjobUid: ToJob.filter((job)=> jobLabel.includes(job.name)).map((job)=>job.uid)}) 
                await Api.post("law/addAssoRole", {lawUid: law.uid, TroleUid: ToRole.filter((role)=> roleLabel.includes(role.name) && law.appUid === role.appUid).map((role)=>role.uid)}) 
                
                let response = await Api.put("law/", law);

                if(response?.success){
                    newLaw = { 
                        law : response?.data.law,
                        job: response?.data.job,
                        role: response?.data.role,
                        app: response?.data.app
                    }
                    setDataLaw((oldData) => oldData.filter(objectLaw => ((objectLaw.law.uid === law.uid) ? newLaw : objectLaw)));
                    setDisplayNotif({
                        open : true,
                        contentText : "Le droit a bien été modifié",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return false;
                }
                    //else create it
            }else{
                let response = await Api.post("law/", law);
                
                await Api.post("law/addAssoJob", {lawUid: response?.data.law.uid, TjobUid: ToJob.filter((job)=> jobLabel.includes(job.name)).map((job)=>job.uid)}) 
                await Api.post("law/addAssoRole", {lawUid: response?.data.law.uid, TroleUid: ToRole.filter((role)=> roleLabel.includes(role.name) && response?.data.law.appUid === role.appUid).map((role)=>role.uid)}) 
                
                if(response?.success){
                    newLaw = { 
                        law : response?.data.law,
                        job: ToJob.filter((job)=> jobLabel.includes(job.name)),
                        role: ToRole.filter((role)=> roleLabel.includes(role.name) && response?.data.law.appUid === role.appUid),
                        app: response?.data.app
                    }
                    //reload table data
                    setDataLaw((newData) => [...newData, newLaw]);
                    setLaw(newLaw.law)
                    handleOpenTinyModal(newLaw.law)
                    setDisplayNotif({
                        open : true,
                        contentText : "Le droit a bien été créé",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return false;
                }
            }
        }else{
            setErrorMessages(error);
            return false;
        }
    }
    //event on salepoint's form
    const formEventApp = async ()=>{
        console.log(arrayJobLabel)
        console.log(arraySalepointLabel)
        let error = displayFormErrors(formRefApp);
        if(Object.keys(error).length === 0){
            //if current salepoint has uid it exist so put
            if(app?.uid){
                let response = await Api.put("app/", app);
                if(response?.success){
                    setDataApp((oldData) => oldData.filter(oldApp => (app.uid === oldApp.uid) ? app : oldApp));
                    setTOApp((oldData) => oldData.filter(oldApp => (app.uid === oldApp.uid) ? app : oldApp));
                    handleCloseModalApp()
                    if(exceptionNumber > 0){
                        Api.del("app/deleteAllAssoSalepointAndJob", {
                            appUid: app.uid
                        })}
                    for (let index = 0; index < exceptionNumber; index++) {
                        let TJobUid = ToJob.filter((job : Job) => { 
                            return arrayJobLabel && arrayJobLabel[index].includes(job.name)
                        }).map((job :Job) => {return job.uid})
                        let TSalepointUid = TOSalepoint.filter((salepoint : Salepoint) => { 
                            return arraySalepointLabel && arraySalepointLabel[index].includes(salepoint.name)
                        }).map((job :Job) => {return job.uid})
                        Api.put("app/addAssoSalepointAndJob", {
                            TJobUid : TJobUid,
                            TSalepointUid : TSalepointUid,
                            appUid: app.uid
                        })
                    } 
                    setDisplayNotif({
                        open : true,
                        contentText : "L'app a bien été modifiée",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return false;
                }
            //else create it
            }else{
                let response = await Api.post("app/", app);
                if(response?.success){
                    let newApp =response?.data
                    setDataApp((newData) => [...newData, newApp]);
                    setApp(newApp)
                    setTOApp((newData) => [...newData, newApp])
                    handleCloseModalApp()
                    for (let index = 0; index < exceptionNumber; index++) {
                        let TJobUid = ToJob.filter((job : Job) => { 
                            return arrayJobLabel && arrayJobLabel[index].includes(job.name) 
                        }).map((job :Job) => {return job.uid})
                        let TSalepointUid = TOSalepoint.filter((salepoint : Salepoint) => { 
                            return arraySalepointLabel && arraySalepointLabel[index].includes(salepoint.name)
                        }).map((job :Job) => {return job.uid})
                        Api.put("app/addAssoSalepointAndJob", {
                            TJobUid : TJobUid,
                            TSalepointUid : TSalepointUid,
                            appUid: response?.data.uid
                        })
                    }
                    setDisplayNotif({
                        open : true,
                        contentText : "L'app a bien été créée",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return false;
                }
            }
        }else{
            setErrorMessages(error);
            return false;
        }
    }
    //event on salepoint's form
    const formEventRole = async ()=>{
        let error = displayFormErrors(formRefRole);
        if(Object.keys(error).length === 0){
            //if current salepoint has uid it exist so put
            if(role?.uid){
                let response = await Api.put("role/", role);
                if(response?.success){
                    setDataRole((oldData) => oldData.filter(oldRole => (role.uid === oldRole.role.uid) ? role : oldRole));
                    setTORole((newData) => newData.filter((oldRole:Role) => (role.uid === oldRole.uid) ? role : oldRole))
                    handleCloseModalRole()
                    
                    setDisplayNotif({
                        open : true,
                        contentText : "Le rôle a bien été modifié",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return false;
                }
            //else create it
            }else{
                let response = await Api.post("role/", role);
                if(response?.success){
                    let newRole =response?.data
                    let object = {role:newRole as Role, app:TOapp.filter(app => (app.uid === newRole.appUid))[0]}
                    setDataRole((newData) => [...newData, object]);
                    setTORole((newData) => [...newData, newRole]);
                    setRole(newRole)
                    handleCloseModalRole()
                    
                    setDisplayNotif({
                        open : true,
                        contentText : "Le rôle a bien été créé",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    return false;
                }
            }
        }else{
            setErrorMessages(error);
            return false;
        }
    }

    const handleChangeTabs = (event: any, value: any) => {
        setValueTabs(value);
        switch(value){
            case 0:
                Api.get("law/").then((response)=>(
                    setDataLaw(response?.data.map(
                        (lawObject:{law : Law, job: Job[], role:Role[], app:App}) => lawObject
                    ))
                ));
                setColumns([
                    { field: "JRA_law.name", headerName: 'Nom', flex:1},
                    { field: "JRA_law.description", headerName: 'Description', flex: 1},
                    { field: 'JRA_job.name', headerName: 'Métiers', flex: 1, minWidth: 90 },
                    { field: 'JRA_role.name', headerName: 'Rôles', flex:1, minWidth: 90 },
                    { field: 'JRA_app.name', headerName: 'Application', flex: 1, minWidth: 90 },
                    { field: 'action', headerName: 'Action', flex:1, type: 'boolean', 
                        renderCell: (params: GridRenderCellParams<any>) => (
                        <>
                            <IconButton title={"Modifier"}>
                                <EditIcon/>
                            </IconButton>
                            <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params, 0)}>
                                <DeleteIcon/>
                            </IconButton>
                        </>),
                    },
                ]);
                break;
            case 1:
                Api.get("app/").then((response)=>(
                    setDataApp(response?.data.map(
                        (app:App) => app
                    )))
                );
                setColumns([
                    { field: "JRA_app.name", headerName: 'Nom', flex:1},
                    { field: 'JRA_app.url', headerName: 'Url', flex: 1, minWidth: 90 },
                    { field: "JRA_app.isJraApp", headerName: 'Est interne', flex:0.19, 
                        renderCell: (params: GridRenderCellParams<any>) => (
                            (params.row['JRA_app.isJraApp']) ? "Oui" : "Non"
                        ),
                    },
                    { field: 'action', headerName: 'Action', flex:1, type: 'boolean', 
                        renderCell: (params: GridRenderCellParams<any>) => (
                        <>
                            <IconButton title={"Modifier"}>
                                <EditIcon/>
                            </IconButton>
                            <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params, 1)}>
                                <DeleteIcon/>
                            </IconButton>
                        </>),
                    },
                ]);
                break;
            case 2:
                Api.get("role/").then((response)=>(
                    setDataRole(response?.data.map(
                        (role:Role) => role
                    )))
                );
                setColumns([
                    { field: "JRA_role.name", headerName: 'Nom', flex:1},
                    { field: 'JRA_app.name', headerName: 'Application(s)', flex: 0.19, minWidth: 90 },
                    { field: 'action', headerName: 'Action', flex:1, type: 'boolean', 
                        renderCell: (params: GridRenderCellParams<any>) => (
                        <>
                            <IconButton title={"Modifier"}>
                                <EditIcon/>
                            </IconButton>
                            <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params, 2)}>
                                <DeleteIcon/>
                            </IconButton>
                        </>),
                    },
                ]);
                break;
        }
    };

    return (
        <div className="background">

        <h1>Gérer les applications</h1>
          <div className="backsquare">
            <div className="tableContainer">
            <div className="lineContain">
                <Button  className='addbutton' 
                         variant="contained" 
                         sx={{width:230}} 
                         onClick={handleOpenModalApp} 
                         endIcon={<AddIcon/>}>
                    1- Créer une application
                </Button>
                <Button  className='addbutton' 
                         variant="contained" 
                         sx={{width:230}} 
                         onClick={handleOpenModalRole} 
                         endIcon={<AddIcon/>}>
                    2- Créer un rôle
                </Button>
                <Button  className='addbutton' 
                         variant="contained" 
                         sx={{width:230}} 
                         onClick={handleOpenModalLaw} 
                         endIcon={<AddIcon/>}>
                    3- Créer un droit
                </Button>
            </div>
            <Tabs className={style.tabsContainer} 
                    value={valueTabs} 
                    onChange={handleChangeTabs} 
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { display: 'none' } }}>
                <Tab className={(0 === valueTabs) ? style.selected : ""} label={"Les Droits"}/>
                <Tab className={(1 === valueTabs) ? style.selected : ""} label={"Les Apps"}/>
                <Tab className={(2 === valueTabs) ? style.selected : ""} label={"Les Rôles"}/>
            </Tabs>
            {
                ((rows && rows.length > 1) 
                ? 
                    <TableChart 
                        data={rows} 
                        columns={columns} 
                        onRowClick={onRowClick}
                        onCardClick={onCardClick}
                        handleDeleteRow={(e)=>(true)}
                        />
                :
                    "")
            }
            <ModalFormComponent 
                title={"Créer un droit"}
                modalOpen={modalOpenLaw} 
                onClose={handleCloseModalLaw} 
                onFormSubmitSuccess={formEventLaw} 
                childrenForm={popupChildrenLaw}
                formEvent={[formEventLaw]}
                setErrorMessages={setErrorMessages}/>

            <ModalFormComponent 
                title={"Créer un rôle"}
                modalOpen={modalOpenRole} 
                onClose={handleCloseModalRole} 
                onFormSubmitSuccess={formEventRole} 
                childrenForm={popupChildrenRole}
                formEvent={[formEventRole]}
                setErrorMessages={setErrorMessages}/>

            <ModalFormComponent 
                title={"Créer une application"}
                modalOpen={modalOpenApp} 
                onClose={handleCloseModalApp} 
                onFormSubmitSuccess={formEventApp} 
                childrenForm={popupChildrenApp}
                formEvent={[formEventApp]}
                setErrorMessages={setErrorMessages}/>

            <TinyModalComponent 
                    title={"Attribuer les droits"}
                    modalOpen={tinyModalOpen} 
                    onClose={handleCloseTinyModal} 
                    child={tinyPopupChild}
                    progess={false}/>
                
            {
                (displayAlert?.open === true) ? <Alert {...displayAlert}/>: ""
            }
            {
                (displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""
            }
            </div>
          </div>
        </div>
      );
}


export default LawPage;