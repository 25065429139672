import { useState, useEffect } from "react";
import style from './openWeather.module.scss';
import { openWeatherKey, openWeatherUrl } from "../../services/tools/openWeatherApiKey";
// @ts-ignore
import ReactAnimatedWeather from 'react-animated-weather';

const COLOR_GOLDENROD = "#FFCB64";
const COLOR_LIGHTBLUE = "#96D1DA";
const COLOR_BLUE = "#3060A7";
const COLOR_BROWN = "#825030";
const COLOR_DARKGRAY = "#696969";
const COLOR_GRAY = "#B6B6B6";

const weatherIcons = {
  Ash: "WIND",
  Clear: "CLEAR_DAY",
  Clouds: "CLOUDY",
  Drizzle: "SLEET",
  Dust: "WIND",
  Fog: "FOG",
  Haze: "CLEAR_DAY",
  Mist: "RAIN",
  Rain: "RAIN",
  Sand: "WIND",
  Smoke: "FOG",
  Snow: "SNOW",
  Squall: "WIND",
  Thunderstorm: "RAIN",
  Tornado: "WIND",
  default: "CLOUDY",
};

const weatherColors = {
  Ash: COLOR_BROWN,
  Clear: COLOR_GOLDENROD,
  Clouds: COLOR_GRAY,
  Drizzle: COLOR_BLUE,
  Dust: COLOR_BROWN,
  Fog: COLOR_BROWN,
  Haze: COLOR_GOLDENROD,
  Mist: COLOR_BLUE,
  Rain: COLOR_BLUE,
  Sand: COLOR_BROWN,
  Smoke: COLOR_BROWN,
  Snow: COLOR_LIGHTBLUE,
  Squall: COLOR_BROWN,
  Thunderstorm: COLOR_DARKGRAY,
  Tornado: COLOR_BROWN,
  default: COLOR_GRAY,
};

const OpenWeather = () => {
  const [weatherData, setWeatherData] = useState({
    city: "",
    temperatureC: 0,
    icon: "CLOUDY",
    color: COLOR_GRAY,
  });

  const getWeather = async (lat: number, lon: number) => {
    const data = await (await fetch(
      `${openWeatherUrl}weather?lat=${lat}&lon=${lon}&units=metric&APPID=${openWeatherKey}&lang=fr`
    )).json();
    const mainWeather = data.weather[0].main as keyof typeof weatherIcons;
  
    setWeatherData({
      city: data.name,
      temperatureC: Math.round(data.main.temp),
      icon: weatherIcons[mainWeather] || weatherIcons.default,
      color: weatherColors[mainWeather] || weatherColors.default,
    });
  };

  useEffect(() => {
    navigator.geolocation?.getCurrentPosition(
      ({ coords }) => getWeather(coords.latitude, coords.longitude),
      () => {
        getWeather(47.186824, -1.601443);
      }
    );
  }, []);

  return (
    <div id={style.openWeather}>
      <p>{weatherData.city}</p>

      <p>{weatherData.temperatureC}°C</p>

      <ReactAnimatedWeather
        icon={weatherData.icon}
        color={weatherData.color}
        size={35}
        animate={true}
      />
    </div>
  );
};

export default OpenWeather;
