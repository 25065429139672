import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { useState, useEffect } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import { useMediaQuery } from "@mui/material";
import Accueil from "./pages/Accueil/Accueil";
import AllPosts from "./pages/Accueil/AllPosts";
import Utilisateur from "./pages/config/Utilisateur/Utilisateur";
import Popins from "./pages/config/Popins/Popins";
import Login from "./pages/Login/Login";
import AccueilConfig from "./pages/config/AccueilConfig/AccueilConfig";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import "./App.scss";
import { createTheme, ThemeProvider } from "@mui/material";
import NoMatch from "./pages/NoMatch/NoMatch";
import Salepoint from "./pages/config/Salepoint/Salepoint";
import useToken from "./services/auth/useToken";
import { useAuth } from "./services/auth/useAuth";
import Header from "./components/header/Header";
import { frFR } from "@mui/x-date-pickers/locales";
import Profile from "./pages/Profile/ProfilePage";
import Popup from "./pages/config/Popins/Popup";
import HierarchyChart from "./pages/HierarchyChart/HierarchyChart";
import Law from "./pages/config/Law/Law";
import AuthCrossOrigin from "./pages/CrossOrigin/AuthCrossOrigin";
import DisconnectCrossOrigin from "./pages/CrossOrigin/DisconnectCrossOrigin";
import { useLaw } from "./services/auth/useLaw";
import { lawConfig, newsConfig, popinConfig, salepointConfig, userConfig } from "./services/object/Law";
import WaitingPage from "./pages/CrossOrigin/WaitingPage";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#008EC8",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#68A5B5",
        dark: "#FFFFFF",
        contrastText: "#FFFFFF",
      },
      info: {
        main: "#585858",
        contrastText: "#FFFFFF",
      },
      success: {
        main: "#04AA6D",
        contrastText: "#FFFFFF",
      },
      error: {
        main: "#d63a5a",
        contrastText: "#FFFFFF",
      },
    },
  },
  frFR
);

function App() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [showScroll, setShowScroll] = useState(false);
  const { token, setToken } = useToken();
  const { isConnected } = useAuth();
  const { law } = useLaw();

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 300) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  if (token === undefined || token.length <= 0 || !isConnected) {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login setToken={setToken} />} />
            <Route path="/mot-de-passe" element={<ForgetPassword />} />
            {/* utilisé pour la connexion des autres applications JRA */}
            <Route
              path="/connexion-crossOrigin"
              element={<AuthCrossOrigin />}
            />
            <Route
              path="/deconnexion-crossOrigin"
              element={<DisconnectCrossOrigin />}
            />
            <Route
              path="/connexion-en-cours"
              element={<WaitingPage />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme} >
      <Router>
        <meta name="google" content="notranslate"/>
          { (!window.location.href.includes("connexion-en-cours")) ? 
              <Header />
            : 
              ""
          }
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/accueil" element={<Accueil />} />
          { (law.filter((law)=>(law.uid === newsConfig)).length === 1) ? 
              <Route path="/accueilConfig" element={<AccueilConfig />} /> 
            : 
              <Route path="*" element={<NoMatch />} />
          }
          { (law.filter((law)=>(law.uid === userConfig)).length === 1) ? 
              <Route path="/utilisateurs" element={<Utilisateur />} /> 
            : 
              <Route path="*" element={<NoMatch />} />
          }
          { (law.filter((law)=>(law.uid === salepointConfig)).length === 1) ? 
              <Route path="/concession" element={<Salepoint />} /> 
            : 
              <Route path="*" element={<NoMatch />} />
          }
          { (law.filter((law)=>(law.uid === popinConfig)).length === 1) ? 
              <Route path="/popins" element={<Popins />} /> 
            : 
              <Route path="*" element={<NoMatch />} />
          }
          { (law.filter((law)=>(law.uid === lawConfig)).length === 1) ? 
              <Route path="/gestion-des-droits" element={<Law />} /> 
            : 
              <Route path="*" element={<NoMatch />} />
          }
          <Route path="/toutes-actus" element={<AllPosts />} />
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route path="*" element={<NoMatch />} />
          {/* utilisé pour la connexion des autres applications JRA */}
          <Route path="/connexion-crossOrigin" element={<AuthCrossOrigin />} />
          <Route
            path="/deconnexion-crossOrigin"
            element={<DisconnectCrossOrigin />}
          />
          <Route
            path="/connexion-en-cours"
            element={<WaitingPage />}
          />
          <Route path='HierarchyChart' element={<HierarchyChart/>}/>
        </Routes>
      </Router>
      <Popup />
      {isMobile && (
        <Zoom in={showScroll}>
          <Fab
            color="primary"
            size="small"
            onClick={scrollTop}
            sx={{ position: "fixed", bottom: 16, right: 16 }}
          >
            <ArrowUpwardIcon />
          </Fab>
        </Zoom>
      )}
    </ThemeProvider>
  );
}

export default App;
