import styleModal from '../../../components/tinyModal/tinyModal.module.scss';
import { Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Law } from '../../../services/object/Law';

interface SalepointTinyPopupChildProps {
    handleCloseTinyModal: () => void,
    handleButtonAddLawUser: (law:Law) => void,
    law:Law
}
const LawTinyChildPopup = ({handleButtonAddLawUser,  handleCloseTinyModal, law} :SalepointTinyPopupChildProps) => {
    return (
        <>
            <p className={styleModal.items_large}>Attribuer ce nouveau droit aux utilisateurs concernés ?</p>
            <div className={styleModal.items}>
                
                <Button variant="outlined"
                        onClick={() => handleCloseTinyModal()} 
                        endIcon={<CloseIcon/>}>
                    Non
                </Button>
                
            </div>
            <div className={styleModal.items}>
                <Button variant="contained" onClick={() => (handleButtonAddLawUser(law))}
                        endIcon={<AddIcon/>}> 
                    Oui
                </Button>
            </div>
        </>
    )
}
export default LawTinyChildPopup;