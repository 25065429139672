import { useAuth } from "./auth/useAuth";
import Cookies from "universal-cookie";
import baseApiUrl from "./baseApiUrl"

export const BASE_API_URL = baseApiUrl;

export interface ResponseProps {
  success: boolean;
  messages: {
    type: string;
    contentText: string;
    code: number;
  };
  recordsTotal: number|undefined,
  recordsFiltered: number|undefined,
  data: any;
}

export const Service_Api = () => {
  const { token, logout } = useAuth();

  const request = async (options: any) => {
    let url = BASE_API_URL + options.url;
    if (options.query) {
      const queryString = new URLSearchParams(options.query).toString();
      if (queryString) {
        url += (url.includes("?") ? "&" : "?") + queryString;
      }
    }
    if (token)
      if (options.headers) options.headers.push({ Authorization: token });
      else options.headers = { Authorization: token };
    let response;
    let api_response: ResponseProps;
    try {
      response = await fetch(url, {
        method: options.method,
        headers: {
          ...options.headers,
        },
        body: JSON.stringify(options.body),
      });
      api_response = await response?.json();

      if (api_response.messages.code === 1000) {
        logout();
        if (!window.location.href.includes("connexion-crossOrigin"))
          window.location.reload();
      }

      return api_response;
    } catch (error) {
      console.log(error);
      // logout();
      // window.location.reload();
    }
  };

  const get = async (url: string, query: string = "", options: {} = {}) => {
    return request({ method: "GET", url, query, ...options });
  };

  const post = async (url: string, body: {} = {}, options: {} = {}) => {
    return request({ method: "POST", url, body, ...options });
  };

  const put = async (url: string, body: {} = {}, options: {} = {}) => {
    return request({ method: "PUT", url, body, ...options });
  };

  const del = async (url: string, body: {} = {}, options: {} = {}) => {
    return request({ method: "DELETE", url, body, ...options });
  };
  return { get, post, put, del };
};

export default Service_Api;
