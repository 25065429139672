
import * as React from "react";
import FormPostStats from "./FormPostStats";
import style from "../styles/accueilConfig.module.scss";
import { PostStats } from "../../../../services/object/PostStats";


interface ModalPostStatsProps {
  errorPostStatsMessages: Record<string, string>;
  handlePostStatsChange: (property: string, value: string) => void;
  formPostStats: PostStats | undefined;
  formRefPostStats: React.RefObject<HTMLFormElement>;
}

export default function ModalPostStats({
  errorPostStatsMessages,
  handlePostStatsChange,
  formPostStats,
  formRefPostStats,
}: ModalPostStatsProps) {
  return [
    <>
      <form id={style.postFormStats} className={`${style.uiform} ${style.postForm}`} ref={formRefPostStats}>
        <FormPostStats
          onChange={handlePostStatsChange}
          postStats={formPostStats}
          errorPostStatsMessages={errorPostStatsMessages}
        />
      </form>
    </>
  ];
}
