import React, { useEffect, useRef, useState } from 'react';
import TableChart from '../../../components/tables/Tablechart';
import AddIcon from '@mui/icons-material/Add';
import { Service_Api } from '../../../services/Api';
import { Salepoint } from '../../../services/object/Salepoint';
import { Button, CircularProgress } from '@mui/material';
import ModalFormComponent from '../../../components/modal/ModalFormComponent';
import { Univers } from '../../../services/object/Univers';
import { GridCallbackDetails, GridRenderCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import { getBase64 } from '../../../services/tools/imgToBase64';
import { OpenningHours } from '../../../services/object/OpenningHours';
import { OpenningHoursException } from '../../../services/object/OpenningHoursException';
import { dateJstoSqlDate, sqlToJsDate } from '../../../services/tools/translateDate';
import salepointPopupChildrens from './SalepointPopupChildrens';
import salepointPopupHoursChildrens from './SalepointPopupHoursChildrens';
import TinyModalComponent from '../../../components/tinyModal/TinyModalComponent';
import SalepointTinyChildPopup from './SalepointTinyPopupChild';
import SalepointTinyChildPopupHours from './SalepointTinyPopupHoursChild';
import Alert, { AlertProps } from '../../../components/alert/Alert';
import { displayFormErrors } from '../../../services/tools/errorForm';
import Notification, { NotificationProps } from '../../../components/notification/Notification';
import { Box } from '@mui/system';
import OpenURLButton from '../../../services/tools/openExternalApp';

function Concession() {
    const [data, setData] = useState<Salepoint[]>([]);
    const [universData, setUniversData] = useState<Univers[]>([]);
    const [salepointData, setSalepointData] = useState<Salepoint[]>([]);
    const [rows, setRows] = useState([{}]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalHoursOpen, setModalHoursOpen] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [tinyModalOpen, setTinyModalOpen] = useState(false);
    const [tinyModalHoursOpen, setTinyModalHoursOpen] = useState(false);
    const [salepoint, setSalepoint] = useState<Salepoint>();
    const [openningHours, setOpenningHours] = useState<OpenningHours[]>();
    const [openningHoursException, setOpenningHoursException] = useState<OpenningHoursException[]>();
    const [selectedImage, setSelectedImage] = useState<string | undefined>(salepoint?.photo);
    const [popupChildren, setPopupChildren] = useState<JSX.Element[]>();
    const [popupHoursChildren, setPopupHoursChildren] = useState<JSX.Element[]>();
    const [checkedUniversHours, setCheckedUniversHours] = useState<string[]>([]);
    const [tinyPopupChild, setTinyPopupChil] = useState<JSX.Element>();
    const [tinyPopupHoursChild, setTinyPopupHoursChil] = useState<JSX.Element>();
    const [displayAlert, setDisplayAlert] = useState<AlertProps>();
    const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
    const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
    const formRefSalepoint = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const formRefHours = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const [salepointLabel, setSalepointLabel] = useState<string[]>([]);

    const Api = Service_Api();
    
    /* hydrate data onload */
    useEffect(() => {
        fecthData()
    }, []);
  
    const fecthData = async() => {
        //get all salepoint (for row data)
        let response = await Api.get("salepoint/");
        setData(response?.data.map(
            (salepoint:Salepoint) => salepoint
        ));
        //get all univers (to hydrate popup's radio input)
        response = await Api.get("univers/");
        setUniversData(response?.data.map(
            (univers:Univers) => univers
        ));
        response = await Api.get("salepoint/");
        setSalepointData(response?.data.map(
            (salepoint:Salepoint)=> salepoint
        ))
    }

    /* end hydrate data */

    /* table */
    //Create column
    const columns = [
        { field: 'progess', type: 'boolean', headerName: 'Complétion',
            renderCell: (params: GridRenderCellParams<any>) => {
                let progress = parseFloat(params.row.progress);
                return <Box className="progress" title="Indice Google">
                            <CircularProgress  className="progressItem" variant="determinate" color={(progress > 80) ? "success" : (progress > 50) ? "warning" : "error"} value={progress} />
                            <Box className="progressContainer">
                            { progress.toFixed(0)}
                        </Box>
                    </Box>
            },
        },
        { field: "nom", headerName: 'Nom', flex:1},
        { field: "adresse", headerName: 'Adresse', flex:1},
        { field: "ville", headerName: 'Ville', flex:1},
        { field: "code", headerName: 'Code Postal', flex:1},
        { field: "siret", headerName: 'SIRET', flex:1},
        { field: "raison_social", headerName: 'Raison social', flex:1},
        { field: 'action', headerName: 'Action', flex:1, type: 'boolean', 
            renderCell: (params: GridRenderCellParams<any>) => (
            <>
                <IconButton title={"Modifier"}>
                    <EditIcon/>
                </IconButton>
                <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params)}>
                    <DeleteIcon/>
                </IconButton>
                <IconButton title={"Diffuser"} onClick={(e) => synchroSalepoint(e, params)}>
                    <ShareIcon/>
                </IconButton>
            </>),
        },
        
    ];
    
    //Create add data in row when const data change
    useEffect(() => {
        setRows(data.map(
            (salepoint:Salepoint) => {
                return  { 
                    "id": salepoint.uid,
                    "nom": salepoint.name,
                    "adresse": salepoint.address,
                    "ville": salepoint.city,
                    "code": salepoint.zip,
                    "siret": salepoint.siret,
                    "raison_social": salepoint.companyName,
                    "progress": salepoint.progress
                }
            }
        ));
    }, [data]);

    const getSalepoint = async(salepointUid:string) => {
        //get salepoint by uid
        const response = await Api.get("salepoint/", "uid="+salepointUid);
        //set current salepoint (to hydrate popup's input)
        setSalepoint({
            uid: response?.data.salepoint.uid,
            name: response?.data.salepoint.name,
            address: response?.data.salepoint.address,
            city: response?.data.salepoint.city,
            zip: response?.data.salepoint.zip,
            gmbId: response?.data.salepoint.gmbId,
            gmbName: response?.data.salepoint.gmbName,
            siret: response?.data.salepoint.siret,
            companyName: response?.data.salepoint.companyName,
            photo: response?.data.salepoint.photo,
            universUid: response?.data.univers.uid,
            phone: response?.data.salepoint.phone,
            secondPhone: response?.data.salepoint.secondPhone,
            description: response?.data.salepoint.description,
            progress: response?.data.salepoint.progress,
            website: response?.data.salepoint.website,
       });
       setModalOpen(true);
    }
    
    const getOpenningHoursBySalepoint = async (salepointUid:string) => {
        const response = await Api.get("openningHours/getOpenningHoursBySalepointUid", "salepointUid="+salepointUid);
        if(response?.success)
            setOpenningHours(response?.data.map(
                (openningHours:any) => {
                    return openningHours
                }
            ));
    }

    const getOpenningHoursExceptionBySalepoint = async (salepointUid:string) => {
        const response = await Api.get("openningHoursException/getOpenningHoursExceptionBySalepointUid", "salepointUid="+salepointUid);
        if(response?.data)
            setOpenningHoursException(response?.data.map(
                (openningHoursException:any) => {
                    return openningHoursException
                }
            ));
    }

    //event click on row
    const onRowClick = (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
        //get current row id (= current salepoint id)
        let salepointUid = params.id as string
        //get current salepoint
        getSalepoint(salepointUid)
        getOpenningHoursBySalepoint(salepointUid)
        getOpenningHoursExceptionBySalepoint(salepointUid)
    }
    //event click on card
    const onCardClick = (salepointUid:string) => {
        //get current row id (= current salepoint id)
        //get current salepoint
        getSalepoint(salepointUid)
        getOpenningHoursBySalepoint(salepointUid)
        getOpenningHoursExceptionBySalepoint(salepointUid)
    }
    //event click on icon delete
    const deleteLine = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams) =>{
        //desable onRowClick event
        event.preventDefault();
        event.stopPropagation();
        setDisplayAlert({
            open : true,
            title : "Suppression",
            contentText : "Êtes-vous sûr de vouloir supprimer cette concession ?",
            contentButtonSuccess : "Oui",
            contentButtonAbord : "Non",
            onSubmitSuccess:  async () => {
                //get current row id (= current salepoint id)
                let salepointUid = params.id as string
                let response = await Api.del("salepoint/", {uid : salepointUid});
                //reload table data
                let newData = data.filter(salepoint => salepoint.uid !== salepointUid)
                setData(newData)
                if(response?.success){
                    setDisplayNotif({
                        open : true,
                        contentText : "La concession a été supprimée",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }
                setDisplayAlert(undefined)
            },
            onSubmitAbord:  () => (setDisplayAlert(undefined))
        })
        
    }

    const synchroSalepoint= async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams) =>{
        //desable onRowClick event
        event.preventDefault();
        event.stopPropagation();
        let salepointUid = params.id as string
        let response = await Api.post("salepoint/synchro", {uid : salepointUid});

        if(response?.success){
            setDisplayNotif({
                open : true,
                contentText : "Mise à jour effectué",
                severity : "success",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }else if(response?.messages.code === 1118){
            setDisplayNotif({
                open : true,
                contentText : "La mise à jour n'a pas pu être effectué",
                severity : "error",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }else{
            setDisplayNotif({
                open : true,
                contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                severity : "error",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }
    }

    /* end table */

    /* popup */
    const resetPopup = () => {
        setSalepoint(undefined)
        setOpenningHours(undefined)
        setOpenningHoursException(undefined)
        setSelectedImage(undefined)
        setErrorMessages({})
    }
    /* popup */
    const resetPopupHours = () => {
        setOpenningHoursException(undefined)
        setSalepointLabel([])
        setCheckedUniversHours([])
    }
    //reset children data when salepoint change
    useEffect(() => {
        let openningHoursOrder = openningHours
        openningHoursOrder?.sort((a, b) => Number(a.hourStart.split(":")[0]) - Number(b.hourStart.split(":")[0]))
        openningHoursOrder?.sort((a, b) => Number(a.day) - Number(b.day))
        let openningHoursExceptionsOrder = openningHoursException
        openningHoursExceptionsOrder?.sort((a, b) => Number(a.hourStart.split(":")[0]) - Number(b.hourStart.split(":")[0]))
        openningHoursExceptionsOrder?.sort((a, b) => sqlToJsDate(a.date).getTime() - sqlToJsDate(b.date).getTime())
        let children = salepointPopupChildrens({errorMessages, 
                                                formRefSalepoint, 
                                                formRefHours, 
                                                salepoint, 
                                                selectedImage, 
                                                universData, 
                                                openningHours: openningHoursOrder, 
                                                openningHoursException :openningHoursExceptionsOrder, 
                                                handleImageChange, 
                                                handleFormSalepointChange, 
                                                handleCircleClick, 
                                                handleFormHoursExceptionChange, 
                                                handleFormHoursChange, 
                                                setOpenningHours,
                                                setOpenningHoursException});
        setPopupChildren(children)
    }, [salepoint, openningHours, openningHoursException, errorMessages, universData]);

    //reset children data when salepoint change
    useEffect(() => {
        let children = salepointPopupHoursChildrens({
                                                errorMessages, 
                                                formRefHours, 
                                                universData, 
                                                checkedUniversHours,
                                                setCheckedUniversHours,
                                                salepointData, 
                                                salepointLabel,
                                                setSalepointLabel,
                                                openningHoursException,
                                                handleFormHoursExceptionChange,
                                                setOpenningHoursException
                                            });
        setPopupHoursChildren(children)
    }, [errorMessages, salepointData, universData, salepointLabel,checkedUniversHours,openningHoursException]);
    

    //event click on icon edit
    const handleCircleClick = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.click();
        }
    };

    //event change on first form
    const handleFormSalepointChange = (property: string, value: string) => {
        //replace old property in current salepoint (input data)
        setSalepoint((salepointValue : any) => ({
        ...salepointValue,
        [property]: value,
      }));
    };
    //event change on image input
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setSelectedImage(URL.createObjectURL(file));
        //convert file to base64 to save it in db
        getBase64(file,(result:string) => {
            //trigger handleFormChange
            handleFormSalepointChange("photo", result)
        })
      } else {
        setSelectedImage(undefined);
      }
    };
    const handleButtonGoogleEvent = async (gmbId : string) => {
        setGoogleLoading(true)
        if(gmbId){
            let response = await Api.get("salepoint/createSalepointByGMBId", "gmbId="+gmbId);
            if(response?.success){
                setSalepoint({
                    uid: response?.data.salepoint.uid,
                    name: response?.data.salepoint.name,
                    address: response?.data.salepoint.address,
                    city: response?.data.salepoint.city,
                    zip: response?.data.salepoint.zip,
                    gmbId: response?.data.salepoint.gmbId,
                    gmbName: response?.data.salepoint.gmbName,
                    siret: response?.data.salepoint.siret,
                    companyName: response?.data.salepoint.companyName,
                    photo: response?.data.salepoint.photo,
                    phone: response?.data.salepoint.phone,
                    secondPhone: response?.data.salepoint.secondPhone,
                    description: response?.data.salepoint.description,
                    progress: response?.data.salepoint.progress,
                    universUid: response?.data.univers.uid,
                    website: response?.data.salepoint.website,
                });
                handleOpenModal()
                getOpenningHoursBySalepoint(response?.data.salepoint.uid)
                getOpenningHoursExceptionBySalepoint(response?.data.salepoint.uid)
                setDisplayNotif({
                    open : true,
                    contentText : "Concession importée depuis GoogleMyBusiness",
                    severity : "success",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }else if(response?.messages.code === 1109){
                setDisplayAlert({
                    open : true,
                    title : "Une concession avec cette identifiant existe déjà:",
                    contentText : "Concession: " + response?.data.salepoint.name + "\\\\nSouhaitez-vous la modifier ?",
                    contentButtonSuccess : "Oui",
                    contentButtonAbord : "Non",
                    onSubmitSuccess:  () => {
                        setDisplayAlert(undefined)
                        onCardClick(response?.data.salepoint.uid)
                    },
                    onSubmitAbord:  () => (setDisplayAlert(undefined))
                })
            }else if(response?.messages.code === 1117){
                setDisplayNotif({
                    open : true,
                    contentText : "Le compte de service Google n'a pas accès à cette fiche GMB. Contactez l'équpe digitale. (code erreur: "+response?.messages.code+")",
                    severity : "error",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }else{
                setDisplayNotif({
                    open : true,
                    contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                    severity : "error",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }
        }
        setGoogleLoading(false)
    }
    const handleClassicButtonEvent = (e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleOpenModal()
    }
    //event on click button create
    const handleOpenTinyModal = () => {
        let child = SalepointTinyChildPopup({handleButtonGoogleEvent, handleClassicButtonEvent});
        setTinyPopupChil(child)
        setTinyModalOpen(true);
        //reset input's data

    }
    //event on click icon close
    const handleCloseTinyModal = () => {
        setTinyModalOpen(false);
        //reset input's data
    };
    const handleClickButtonTinyModalHours = () => {
        handleCloseTinyModalHours()
        handleCloseModalHours();
    }

    //event on click button create
    const handleButtonGoogleSubmit = async () => {
        let TsalepointUidPost = (salepointData.filter((salepoint : Salepoint) => { return salepointLabel.indexOf(salepoint.name) > -1})).map((salepoint :Salepoint) => {return salepoint.uid})
        const response = await Api.post("salepoint/synchroAllByTuid", {
            TsalepointUid : TsalepointUidPost
        });
        if(response?.success){
            setDisplayNotif({
                open : true,
                contentText : "Horaires exceptionnels ajoutés",
                severity : "success",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }else{
            setDisplayNotif({
                open : true,
                contentText : "Une erreur est survenue",
                severity : "error",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }
        handleCloseTinyModalHours()
        handleCloseModalHours();
    }

    //event on click button create
    const handleOpenTinyModalHours = () => {
        let child = SalepointTinyChildPopupHours({handleButtonGoogleSubmit,  handleClickButtonTinyModalHours});
        setTinyPopupHoursChil(child)
        setTinyModalHoursOpen(true);
        setErrorMessages({});

        //reset input's data

    }
    //event on click icon close
    const handleCloseTinyModalHours = () => {
        setTinyModalHoursOpen(false);
        //reset input's data
    };
    //event on click button create
    const handleOpenModal = () => {
        setModalOpen(true);
        handleCloseTinyModal();
        handleCloseTinyModalHours();
        setDisplayAlert(undefined);
        //reset input's data
    };
    
    //event on click icon close
    const handleCloseModal = () => {
        setModalOpen(false);
        handleCloseTinyModal();
        handleCloseTinyModalHours();
        //reset input's data
        resetPopup();
    };

    //event on click button create
    const handleOpenModalHours = () => {
        setModalHoursOpen(true);
    };
    
    //event on click icon close
    const handleCloseModalHours = () => {
        setModalHoursOpen(false);
        //reset input's data
        resetPopupHours();
    };
    //event on salepoint's form
    const formEventSalepoint = async ()=>{
        let error = displayFormErrors(formRefSalepoint);
        if(Object.keys(error).length === 0){
            //if current salepoint has uid it exist so put
            if(salepoint?.uid){
                Api.put("salepoint/", salepoint);
                let oldData = data;
                const index = oldData.findIndex(oldSalepoint => oldSalepoint.uid === salepoint.uid);
                oldData = oldData.filter(oldSalepoint => oldSalepoint.uid !== salepoint.uid)
                oldData[index] = salepoint;
                setData(oldData);
            //else create it
            }else{
                let response = await Api.post("salepoint/", salepoint);
                getOpenningHoursBySalepoint(response?.data.uid);
                let newSalepont = { 
                    uid: response?.data.uid,
                    name: response?.data.name,
                    address: response?.data.address,
                    city: response?.data.city,
                    zip: response?.data.zip,
                    gmbId: response?.data.gmbId,
                    gmbName: response?.data.gmbName,
                    siret: response?.data.siret,
                    companyName: response?.data.companyName,
                    photo: response?.data.photo,
                    universUid: response?.data.universUid,
                    phone: response?.data.salepoint.phone,
                    secondPhone: response?.data.salepoint.secondPhone,
                    description: response?.data.salepoint.description,
                    progress: response?.data.salepoint.progress,
                    website: response?.data.salepoint.website,
                }
                //reload table data
                let newData = data;
                newData.unshift(newSalepont)
                setData(newData);
                //set new current salepoint
                setSalepoint(newSalepont)
            }
            return true;
        }else{
            setErrorMessages(error);
            return false;
        }
    }
    //event on hour's form
    const formEventHoursMass = async ()=>{
        let error = displayFormErrors(formRefHours);
        if(Object.keys(error).length === 0){
            let TsalepointUidPost = (salepointData.filter((salepoint : Salepoint) => { return salepointLabel.indexOf(salepoint.name) > -1})).map((salepoint :Salepoint) => {return salepoint.uid})
            if(openningHoursException !== undefined){
                const response = await Api.post("openningHoursException/createOpenningHoursForOneOrMoreSalepoint", {
                    openningHoursException : openningHoursException,
                    TsalepointUid : TsalepointUidPost
                });
                if(response?.success){
                    setDisplayNotif({
                        open : true,
                        contentText : "Horaires exceptionnels ajoutés",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Une erreur est survenue",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }
                handleOpenTinyModalHours();
                return true;
            }else{
                setErrorMessages({"openningHoursException": "Vous devez renseigner au moins une exception"});
                return false;
            }
        }else{
            setErrorMessages(error);
            return false;
        }
    }
    //event on hour's form
    const formEventHours = async ()=>{
        openningHours?.forEach(openningHours => {
            //if current salepoint has uid it exist so put
            if(openningHours?.uid && !openningHours?.uid.includes("-"))
                Api.put("openningHours/", openningHours);
            //else create it
            else{
                Api.post("openningHours/", openningHours);
            }
        });
        openningHoursException?.forEach(openningHoursException => {
            //if current salepoint has uid it exist so put
            if(openningHoursException?.uid && !openningHoursException?.uid.includes("-"))
                Api.put("openningHoursException/", openningHoursException);
            //else create it
            else{
                Api.post("openningHoursException/", openningHoursException);
            }
        });
        handleCloseModal();
        setDisplayNotif({
            open : true,
            contentText : "Concession enregistrée",
            severity : "success",
            handleClose:  ()=>(setDisplayNotif(undefined))
        })
        return true;
    }

    const handleFormHoursChange = (uid :string, property: keyof OpenningHours, value: never) => {
        //get current hours
        let newOpenningHours:OpenningHours|undefined= openningHours?.filter(openningHours => openningHours.uid === uid)[0]
        if(newOpenningHours && typeof newOpenningHours !== undefined){
            //get all possible key for oppeningHours object
            newOpenningHours[property] = value;
            //replace old property in current hour (= current input data)
            let newData = openningHours?.filter(openningHours => openningHours.uid !== uid);
            newData?.push(newOpenningHours)
            //sort by date && buy hour
            newData?.sort((a, b) => Number(a.hourStart.split(":")[0]) - Number(b.hourStart.split(":")[0]))
            newData?.sort((a, b) => Number(a.day) - Number(b.day))
            setOpenningHours(newData);
        }
    };

    const handleFormHoursExceptionChange = (uid :string, property: keyof OpenningHoursException, value: never) => {
        //get current hours exception
        let newOpenningHoursException:OpenningHoursException|undefined = openningHoursException?.filter(openningHoursException => openningHoursException.uid === uid)[0]
        if(newOpenningHoursException && typeof newOpenningHoursException !== undefined){
            //get all possible key for oppeningHoursException object
            newOpenningHoursException[property] = value;
            //replace old property in current hour (= current input data)
            let newData = openningHoursException?.filter(openningHoursException => openningHoursException.uid !== uid);
            newData?.push(newOpenningHoursException)
            newData?.sort(
                (a, b) => 
                ((a.hourStart && b.hourStart) ? Number(a.hourStart.split(":")[0]) - Number(b.hourStart.split(":")[0]) : 0)
            )
            newData?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            setOpenningHoursException(newData);
        }
    };
            

    const unsupportedURL = "testopenappreact://ouvrir_paint";
    return (
        <div className="background">

        <h1>Gérer les concession</h1>
          <div className="backsquare">
            <div className="tableContainer">
            <div className="lineContain">
                {/* <OpenURLButton url={unsupportedURL} content={"Ouvrir Paint"}/> */}
                <Button  className='addbutton' 
                         variant="contained" 
                         sx={{width:230}} 
                         onClick={handleOpenModalHours} 
                         endIcon={<AddIcon/>}>
                    Ajouter des horaires exceptionnels
                </Button>
                <Button  className='addbutton' 
                         variant="contained" 
                         sx={{width:230}} 
                         onClick={handleOpenTinyModal} 
                         endIcon={<AddIcon/>}>
                    Créer une concession
                </Button>
            </div>
                {
                    ((rows && rows.length > 1) 
                    ? 
                        <TableChart 
                            data={rows} 
                            columns={columns} 
                            onRowClick={onRowClick}
                            onCardClick={onCardClick}
                            handleDeleteRow={(e)=>(true)}
                            />
                    :
                        "")
                }
                <ModalFormComponent 
                    title={"Créer une concession"}
                    modalOpen={modalOpen} 
                    onClose={handleCloseModal} 
                    onFormSubmitSuccess={formEventHours} 
                    childrenForm={popupChildren}
                    formEvent={[formEventSalepoint, formEventHours]}
                    setErrorMessages={setErrorMessages}/>
                    
                <TinyModalComponent 
                    title={"Créer une concession"}
                    modalOpen={tinyModalOpen} 
                    onClose={handleCloseTinyModal} 
                    child={tinyPopupChild}
                    progess={googleLoading}/>

                <ModalFormComponent 
                    title={"Ajouter des horaires exceptionnels"}
                    modalOpen={modalHoursOpen} 
                    onClose={handleCloseModalHours} 
                    onFormSubmitSuccess={formEventHoursMass} 
                    childrenForm={popupHoursChildren}
                    formEvent={[formEventHoursMass]}
                    setErrorMessages={setErrorMessages}/>

                <TinyModalComponent 
                    title={"Diffuser sur l'ensemble des supports ?"}
                    modalOpen={tinyModalHoursOpen} 
                    onClose={handleCloseTinyModalHours} 
                    child={tinyPopupHoursChild}
                    progess={googleLoading}/>

                {
                    (displayAlert?.open === true) ? <Alert {...displayAlert}/>: ""
                }
                {
                    (displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""
                }
            </div>
          </div>
        </div>
      );
}


export default Concession;