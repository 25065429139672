import { useState, useEffect, useRef } from 'react';
import {Service_Api} from '../../../services/Api';
import TableChart from '../../../components/tables/TableUser';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ModalFormComponent from '../../../components/modal/ModalFormComponent';
import style from './Popin.module.scss';
import { GridCallbackDetails, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import ModalPopin from './ModalPopin';
import {Popin} from '../../../services/object/Popin';
import {Salepoint} from '../../../services/object/Salepoint';
import {Role} from '../../../services/object/Role';
import {Univers} from '../../../services/object/Univers';
import { Environnement } from '../../../services/object/Environnement';
import Alert, { AlertProps } from '../../../components/alert/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Notification, { NotificationProps } from '../../../components/notification/Notification';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import { sqlToJsDate, jsDateToLocalFr, localFrToJsDate } from '../../../services/tools/translateDate';
import { displayFormErrors } from '../../../services/tools/errorForm';
import { Job } from '../../../services/object/Job';
import { App } from '../../../services/object/App';

export interface PopinData {
  popin: Popin;
  salepoint: Salepoint[];
  univers: Univers[];
  role: Role[];
  app: App[];
  environnement: Environnement[];
  job: Job[];
}

export interface ChildProps {
  uid: string;
  parentUid: string;
  value: boolean;
}

function PopinComponent() {
  
  const [app, setApp] = useState<App[]>([]);
  const [role, setRole] = useState<Role[]>([]);
  const [checkedRole, setCheckedRole] = useState<string[]>([]);
  const [checkedApp, setCheckedApp] = useState<string[]>([]);
  const [childCheckedRole, setChildCheckedRole] = useState<ChildProps[]>([{ uid: "", parentUid: "", value: false },]);

  const [univers, setUnivers] = useState<Univers[]>([]);
  const [T_O_salepoint, setSalepoint] = useState<Salepoint[]>([]);
  const [checkedSalepoint, setCheckedSalepoints] = useState<string[]>([]);
  const [checkedUnivers, setCheckedUnivers] = useState<string[]>([]);
  const [childCheckedSalepoint, setChildCheckedSalepoint] = useState<ChildProps[]>([{ uid: "", parentUid: "", value: false },]); 

  const [checkedEnvironnement, setCheckedEnvironnement] = useState<string[]>([]);
  const [checkedJob, setCheckedJob] = useState<string[]>([]);
  const [environnement, setEnvironnement] = useState<Environnement[]>([]);
  const [job, setJob] = useState<Job[]>([]);
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
  const [displayAlert, setDisplayAlert] = useState<AlertProps>();
  const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
  const [isNewPopin, setIsNewPopin] = useState(true);
  const [popupChildren, setPopupChildren] = useState<JSX.Element[]>();
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [Rows, setRows] = useState<{ id: string; title: string; dateStart: string; dateEnd: string; createur: string; destinataire: number}[]>([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [popins, setPopins] = useState<Popin[]>([]);
  const [popinData, setPopinData] = useState<PopinData>({ popin: {uid: "",title: "",content: "",imageUrl: "",dateStart: "",dateEnd: "",buttonText:"",createdBy:"", userNumber:0}, salepoint: [], univers: [], role: [], app: [], environnement: [], job: []});
  const [formPopins, setFormPopins] = useState<Popin>({uid: "",title: "",content: "",imageUrl: "",dateStart: "",dateEnd: "",buttonText:"",createdBy:"", userNumber:0});
  const formRefPopin = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const formRefSalepoint = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const formRefRole = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const Api = Service_Api(); 
  const [loading, setLoading] = useState(false);
  const [maxPopin, setMaxPopin] = useState<number>(0);

  const getPopinStatus = (dateStart: string, dateEnd: string): { statusText: string; chipColor: 'error' | 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | undefined } => {
    const today = dayjs();
    const startDate = dayjs(localFrToJsDate(dateStart));
    const endDate = dayjs(localFrToJsDate(dateEnd));
    let statusText;
    let chipColor: 'error' | 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | undefined;

    if (today.isSame(startDate) || today.isAfter(startDate)) {
        if ( dateEnd === "" || today.isBefore(endDate) || today.isSame(endDate)) {
            statusText = 'Publié';
            chipColor = 'success';
        } else {
            statusText = 'Terminé';
            chipColor = 'default';
        }
    } else {
        statusText = 'Non publié';
        chipColor = 'primary';
    }

    return { statusText, chipColor };
  };

  useEffect(() => {
    setPopinData({ popin: {uid: "",title: "",content: "",imageUrl: "",dateStart: "",dateEnd: "",buttonText:"",createdBy:"", userNumber: 0}, salepoint: [], univers: [], role: [], app: [], environnement: [], job: []});
    fetchRole();
    fetchApp();
    fetchJob();
    fetchSalepoint();
    fetchPlate();
    fetchEnvionnement();
    if(!isModalOpen) {
      setErrorMessages({});
    }
  }, [isModalOpen])

  const fetchRole = async () => {
    try {
      const response = await Api.get('role/');
        setRole(response?.data); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };

  const filterAppsWithRoles = (apps: App[], roles: Role[]): App[] => {
    return apps.filter(app => roles.find(role => role.appUid === app.uid));
  };

  const fetchApp = async () => {
    try {
      let jraApps: App[] = [];
      const response = await Api.get('app/');
      response?.data.forEach(async (app: App) => {
        if (app.isJraApp === true) {
          jraApps.push(app);
        } 
      });
      const appsWithRoles = filterAppsWithRoles(jraApps, role);
      setApp(appsWithRoles);
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };

  const fetchJob = async () => {
    try {
      const response = await Api.get('job/');
        setJob(response?.data); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };


  const fetchSalepoint = async () => {
    try {
      const response = await Api.get('salepoint/');
      setSalepoint(response?.data); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };

  const fetchPlate = async () => {
    try {
      const response = await Api.get('univers/');
      setUnivers(response?.data); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };

  const fetchEnvionnement = async () => {
    try{
      const response = await Api.get('environnement/')
      setEnvironnement(response?.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des données')
    }
  }

  const handleOpenModal = () => {
    setFormPopins({
      uid: "",
      title: "",
      content: "",
      imageUrl: "",
      dateStart: "",
      dateEnd: "",
      buttonText:"",
      createdBy:"",
      userNumber: 0
    });
    setCheckedRole([]);
    setCheckedApp([]);
    setCheckedSalepoints([]);
    setCheckedUnivers([]);
    setCheckedEnvironnement([]);
    setCheckedJob([]);
    setIsNewPopin(true);
    setIsRowClicked(false);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setErrorMessages({});
    if (isNewPopin) {
      handleDeletePopin()
    }
  };

  const handleSubmitPopin = async () => {
    let popindatavar : PopinData =  popinData;
    try {
      if (formPopins) {
        const data = { ...formPopins };
        const response = await Api.post('popin/', data);
        popindatavar.popin = response?.data;
        setPopinData(popindatavar);
        handlePopinChange("uid", response?.data.uid);
        console.log('Formulaire soumis avec succès', response);
      };
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire');
    };
  };

  const handleUpdatePopin = async () => {
    let popindatavar : PopinData =  popinData;
    try {
      const data = formPopins;
      const response = await Api.put(`popin/`, data);
      popindatavar.popin = response?.data
      setPopinData(popindatavar);
      console.log('Pop-In mis à jour avec succès', response);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la Pop-In', error);
    }
  };

  const handleDeletePopin = async () => {
    try {
      if (formPopins?.uid) {
        await Api.del(`popin/`, {uid: formPopins?.uid});
        console.error('Pop-In supprimé avec succès');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la Pop-In', error);
    }
  };


  const handleSubmitRole = async (): Promise<boolean> => {
    let popindatavar : PopinData =  {...popinData};
    let tableApp: App[] = [];
    let uidsToPost: string[] = [];
    let uidsToDelete: string[] = [];
    let uniqueApp : App[] =[];
    let response: any = null;
    try {
      if (formPopins?.uid && popindatavar) {
        for (const child of childCheckedRole ) {
          if (child.value) {
            uidsToPost.push(child.uid);
          } else {
            uidsToDelete.push(child.uid);
          }
        }
        response = await Api.post('popin/changeAssoAllRole', { Tuid: uidsToPost, popinUid: formPopins?.uid });
          if (response.success) {
            if (response.data.app) {
              uniqueApp = tableApp.concat(response.data.app).filter((app, index, self) => {
                return index === self.findIndex(element => element.uid === app.uid);
              });
              tableApp = uniqueApp;
            }
            popindatavar.role = response.data.role;
            popindatavar.app = tableApp;
          } else {
            console.error('Failed to associate uidsToDelete:', uidsToPost);
          }
        
        setCheckedRole(uidsToPost);
        setPopinData(popindatavar);
        if (uniqueApp.length > 0) {
          setCheckedApp(uniqueApp.map((app: App) => app.uid));
        }
        
          response = await Api.del('popin/changeAssoAllRole', { Tuid: uidsToDelete, popinUid: formPopins?.uid });
          if (!response.success) {
            console.error('Failed to delete association for uidsToDelete:', uidsToDelete);
          }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire', error);
      return false;
    }
};

  const handleSubmitJob = async () => {
    try{
      if(formPopins?.uid) {
        let popindatavar : PopinData = {...popinData}
        let uidsToPost: string[] = [];
        let uidsToDelete: string[] = [];

        for (const O_job of job) {
          if (checkedJob.includes(O_job.uid)) {
              uidsToPost.push(O_job.uid);
          } else {
              uidsToDelete.push(O_job.uid);
          }
        }

          if (uidsToPost.length > 0) {
            const response = await Api.post('popin/changeAssoAllJob', {Tuid: uidsToPost, popinUid: formPopins?.uid});
            if (response?.success) {
              popindatavar.job = response?.data.job;
            } else {
              console.error('Failed to post jobs:', uidsToPost);
              return false;
            }
          }

         if (uidsToDelete.length > 0) {
              const response = await Api.del('popin/changeAssoAllJob', {Tuid: uidsToDelete, popinUid: formPopins?.uid});
              if (response?.success) {
                const deletedUids = response.data;
                popindatavar.job = popindatavar.job.filter(job => !deletedUids.includes(job.uid))
              } else {
                console.error('Failed to delete jobs:', uidsToDelete);
                return false;
              } 
          }
        setPopinData(popindatavar)
      }
    } catch (error) {
      console.log('Erreur lors de la soumission du formulaire', error);
    }
    return true;
  };

  const handleSubmitEnvironnement = async () => {
    try {
      if (formPopins?.uid) {
        let popindatavar: PopinData = { ...popinData };
        let uidsToPost: string[] = [];
        let uidsToDelete: string[] = [];

        for (const O_env of environnement) {
          if (checkedEnvironnement.includes(O_env.uid)) {
              uidsToPost.push(O_env.uid);
          } else {
              uidsToDelete.push(O_env.uid);
          }
        }

        
          if (uidsToPost.length > 0) {
            const response = await Api.post('popin/changeAssoAllEnvironnement', { Tuid: uidsToPost, popinUid: formPopins?.uid });
            if (response?.success) {
              popindatavar.environnement = response?.data.environnement;
            } else {
              return false;
            }
          }
          if (uidsToDelete.length > 0) {
            const response = await Api.del('popin/changeAssoAllEnvironnement', { Tuid: uidsToDelete, popinUid: formPopins?.uid });
            if (response?.success) {
              const deletedUids = response.data;
              popindatavar.environnement = popindatavar.environnement.filter(environnement => !deletedUids.includes(environnement.uid));
            }else {
              return false
            }
          }
        setPopinData(popindatavar);
      }
    } catch (error) {
      console.log('Erreur lors de la soumission du formulaire', error);
    }
    return true;
  };

  const handleSubmitSalepoint = async (): Promise<boolean> => {
    let popindatavar : PopinData =  {...popinData};
    let tableUnivers: Univers[] = [];
    let uidsToPost: string[] = [];
    let uidsToDelete: string[] = [];
    let uniqueUnivers : Univers[] =[];
    let response: any = null;
    try {
      if (formPopins?.uid && popindatavar) {
        for (const child of childCheckedSalepoint ) {
          if (child.value) {
            uidsToPost.push(child.uid);
          } else {
            uidsToDelete.push(child.uid);
          }
        }
        response = await Api.post('popin/changeAssoAllSalepoint', { Tuid: uidsToPost, popinUid: formPopins?.uid });
          if (response.success) {
            if (response.data.univers) {
              uniqueUnivers = tableUnivers.concat(response.data.univers).filter((univers, index, self) => {
                return index === self.findIndex(element => element.uid === univers.uid);
              });
              tableUnivers = uniqueUnivers;
            }
            popindatavar.salepoint = response.data.salepoint;
            popindatavar.univers = tableUnivers;
          } else {
            console.error('Failed to associate uidsToDelete:', uidsToPost);
          }
        
        setCheckedSalepoints(uidsToPost);
        setPopinData(popindatavar);
        if (uniqueUnivers.length > 0) {
          setCheckedUnivers(uniqueUnivers.map((univers: Univers) => univers.uid));
        }
        
          response = await Api.del('popin/changeAssoAllSalepoint', { Tuid: uidsToDelete, popinUid: formPopins?.uid });
          if (!response.success) {
            console.error('Failed to delete association for uidsToDelete:', uidsToDelete);
          }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire', error);
      return false;
    }
};

  const handleSavePopin = async (): Promise<boolean> => {
    let error = displayFormErrors(formRefPopin);
    if(Object.keys(error).length === 0){
      try {
        if (formPopins?.uid) {
          await handleUpdatePopin();
        } else {
          await handleSubmitPopin();
        }

      } catch (error) {
        console.error('Erreur lors de la soumission ou de la mise à jour de la Popo-In', error);
      }
      return true;
    } else {
      setErrorMessages(error);
      return false;
    }
  };


  const handleSaveSalepoint = async (): Promise<boolean> => {
    let error = displayFormErrors(formRefSalepoint);
      if (Object.keys(error).length === 0) {
            await handleSubmitSalepoint();
            await handleSubmitEnvironnement();
            return true; 
      } else {
          setErrorMessages(error);
          return false;
      }
    };

  const handleSave = async (): Promise<boolean> => { 
    let error = displayFormErrors(formRefRole);
    if (Object.keys(error).length === 0) {
      if(await handleSubmitRole() && await handleSubmitJob()){
        onFormSubmitSuccess(popinData);
        setIsModalOpen(false);
        return true;
      } else {
        setErrorMessages(error);
        return false;
      }
    } else {
      setErrorMessages(error);
      return false;
    }
  };

  const handlePopinChange = (property: string, value: string) => {
    setFormPopins((prevFormPopins: any) => ({
      ...prevFormPopins,
      [property]: (value === '' ? null : value),
    }));
  };


  const getRowData = async (paginationModel: { page: number; pageSize: number;}, sort: {}, filter: {}) => {
    // fetch data from server
    setLoading(true)
    const response = await Api.post("popin/getAllPopinForDataGrid", {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sort,
        filter
      });
    if(response?.success){
        setMaxPopin(response.data[response.data.length-1].max.countPopin)
        delete response?.data[response.data.length-1];
        setPopins(response?.data);
        setLoading(false)

    }else{
        setPopins([]);
        setLoading(false)
    }
  };

  const onFormSubmitSuccess = async (updatedData: PopinData) => {
    try {
      const response = await Api.get("popin/getUserNumByPopinUid", `popinUid=${updatedData.popin.uid}`);
      setPopins((prevPopins) => {
        const validPopins = prevPopins.filter(popin => popin && popin.uid);
        const popinIndex = validPopins.findIndex(popin => popin.uid === updatedData.popin.uid);
        let updatedPopin = updatedData
        updatedPopin.popin.userNumber = response?.data.userNumber
        if(popinIndex !== -1) {
          const updatedPopins = prevPopins.map((popin, index) => index === popinIndex ? updatedPopin.popin : popin);
          setDisplayNotif({
            open: true,
            contentText: "Les données popin ont été mises à jour avec succès.",
            severity: "success",
            handleClose: () => setDisplayNotif(undefined),
          });
          return updatedPopins;

        } else {
          setDisplayNotif({
            open: true, 
            contentText: "La popin a été enregistré avec succès.",
            severity: "success",
            handleClose: () => setDisplayNotif(undefined),
          });
          return [updatedPopin.popin, ...prevPopins];
        }     
      });
    } catch (error) {
     console.error('Erreur lors de la mise à jours des données popin', error);
     setDisplayNotif({
       open: true,
       contentText: "Une erreur est survenue lors de la mise à jour des données popin.",
       severity: "error",
       handleClose: () => setDisplayNotif(undefined),
     });
    }
 };

  const getPopinDetails = async (popinId: string) => {
    try {
      const response = await Api.get('popin/', `uid=${popinId}`);
      if (response?.data) {
        setFormPopins({
          uid: response?.data.uid,
          title: response?.data.title,
          content: response?.data.content,
          imageUrl: response.data.imageUrl,
          dateStart: response?.data.dateStart,
          dateEnd: response?.data.dateEnd,
          buttonText: response?.data.buttonText,
          createdBy: response?.data.createdBy,
          userNumber: response?.data.userNumber
        });
        if (response?.data.role) {
          const popinRole = response.data.role.map((role: Role) => role.uid);
          setCheckedRole(popinRole);
        }
        if (response?.data.app) {
          const popinApp = response.data.app.map((app: App) => app.uid);
          setCheckedApp(popinApp);
        }
        if (response?.data.salepoint) {
            const popinSalepoints = response.data.salepoint.map((salepoint: Salepoint) => salepoint.uid);
            setCheckedSalepoints(popinSalepoints);            
        }
        if (response?.data.univers) {
          const popinUnivers = response?.data.salepoint.map((salepoint: Salepoint) => salepoint.universUid);
            setCheckedUnivers(popinUnivers)
        }
        if (response?.data.environnement) {
            const popinEnvironnement = response.data.environnement.map((environnement: Environnement) => environnement.uid);
            setCheckedEnvironnement(popinEnvironnement);
        }
        if (response?.data.job) {
          const popinJob = response.data.job.map((job: Job) => job.uid);
          setCheckedJob(popinJob);
        }
        setIsNewPopin(false);
      } else {
        console.error('Réponse de détails de popin invalide:', response);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des détails de la popin', error);
    }  
  };

  const onRowClick = (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
    const popinId = params.id as string;
    getPopinDetails(popinId);
    setIsRowClicked(true);
    setIsModalOpen(true);
  };
  
  const onCardClick = (popinId: string) => {
    getPopinDetails(popinId);
    setIsRowClicked(true);
    setIsModalOpen(true);
  }

  const handleDeleteRow = async (popinId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setDisplayAlert({
      open: true,
      title: "Suppression",
      contentText: "Êtes-vous sûr de vouloir supprimer cet Pop_In ?",
      contentButtonSuccess: "Oui",
      contentButtonAbord: "Non",
      onSubmitSuccess: async () => {
        try {
          await Api.del("popin/", { uid: popinId });
          const updatedUsers = popins.filter((data: Popin) => data.uid !== popinId);
          setPopins(updatedUsers);
          setDisplayNotif({
            open: true,
            contentText: "La Pop-In a été supprimé",
            severity: "success",
            handleClose: () => setDisplayNotif(undefined),
          });
        } catch (error) {
          console.error("Erreur lors de la suppression de la Pop-In", error);
          setDisplayNotif({
            open: true,
            contentText: "Aïe, une erreur est survenue lors de la suppression de la Pop-In.",
            severity: "error",
            handleClose: () => setDisplayNotif(undefined),
          });
        } finally {
          setDisplayAlert(undefined);
        }
      },
      onSubmitAbord: () => setDisplayAlert(undefined),
    });
  };

  const columns = [
    { 
      field: 'statut', 
      headerName: 'Statut', 
      flex: 0.1, 
      minWidth:  120,
      renderCell: (params: any) => {
        const dateStart = params.row['JRA_popin.dateStart'];
        const dateEnd = params.row['JRA_popin.dateEnd'];
        const { statusText, chipColor } = getPopinStatus(dateStart, dateEnd);
        return (
          <Chip label={statusText} color={chipColor} variant="outlined" sx={{width: '90px'}}/>
        );
      },
    },  
    { field: 'JRA_popin.title', headerName: 'Titre', flex: 0.14, minWidth:  140 },
    { field: 'JRA_popin.dateStart', headerName: 'Date Début', flex: 0.14, minWidth: 140 },
    { field: 'JRA_popin.dateEnd', headerName: 'Date Fin', flex: 0.14, minWidth: 130 },
    { field: 'JRA_popin.createdBy', headerName: 'Créateur', flex: 0.14, minWidth:  90 },
    { field: 'JRA_popin.userNumber', headerName: 'Utilisateurs Concernés', flex: 0.12, minWidth:  90 },
    {
      field: 'actions',
      headerName: '',
      width: 90,
      renderCell: (params: any) => (
        <div>
          <IconButton>
            <EditIcon color="action" />
          </IconButton>
          <IconButton onClick={(event) => handleDeleteRow(params.row.id, event)}>
            <DeleteIcon color="action" />
          </IconButton>
        </div>
      ),
    },
  ];


  useEffect(() => {
    let children = ModalPopin({
      errorMessages, 
      checkedSalepoint,
      checkedUnivers,
      checkedEnvironnement,
      checkedJob,
      setChildCheckedSalepoint,
      childCheckedSalepoint,
      T_O_salepoint,
      univers,
      checkedRole,
      checkedApp,
      setChildCheckedRole,
      childCheckedRole,
      role,
      app,
      setCheckedEnvironnement,
      environnement,
      formPopins,
      handlePopinChange,
      formRefPopin, 
      formRefRole,
      isRowClicked,
      formRefSalepoint,
      job,
      setCheckedJob
    });
    setPopupChildren(children);
  }, [childCheckedRole, checkedRole, checkedApp, checkedJob, checkedEnvironnement, childCheckedSalepoint, checkedSalepoint, checkedUnivers, formPopins, errorMessages]);

  const searchFields = [
    'JRA_popin.title',
    'JRA_popin.dateStart',
    'JRA_popin.dateEnd',
  ];

    return (
          <div className="background">
            <h1>Gérer les popins</h1>
            <div className="backsquare">
              <div className='tableContainer'>
                  <div style={{ cursor: isLoading ? 'wait' : 'default' }} className={style.lineContain}>
                      <Button  className={style.addbutton}  variant="contained" sx={{width:230}} onClick={handleOpenModal} endIcon={<AddIcon/>}>
                        Nouvelle popin 
                      </Button>
                  </div>
                  <>
                  {<ModalFormComponent
                      title={ isNewPopin ? "Nouvelle Popin" : "modification de la Popin"}
                      modalOpen={isModalOpen}
                      onClose={handleCloseModal}
                      onFormSubmitSuccess={handleSave}
                      childrenForm={popupChildren}
                      formEvent={[handleSavePopin, handleSaveSalepoint, handleSave]}
                      setErrorMessages={setErrorMessages}/>}
                    <TableChart 
                    columns={columns} 
                    dataRow={popins?.map((data: Popin) => {
                      if (data) {
                        const startDate = data.dateStart ? jsDateToLocalFr(sqlToJsDate(data.dateStart)) : '';
                        const endDate = data.dateEnd ? jsDateToLocalFr(sqlToJsDate(data.dateEnd)) : '';
                        const { statusText, chipColor } = getPopinStatus(startDate, endDate);
                        const mainPopinData = {
                          id: data.uid || '',
                          statut: { statusText, chipColor },
                          'JRA_popin.title': data.title || '', 
                          'JRA_popin.dateStart': (data.dateStart && data.dateStart !== '') ? jsDateToLocalFr(sqlToJsDate(data.dateStart)) : '',
                          'JRA_popin.dateEnd': (data.dateEnd && data.dateEnd !== '') ? jsDateToLocalFr(sqlToJsDate(data.dateEnd)) : '',
                          'JRA_popin.userNumber': data.userNumber || 0,
                          'JRA_popin.createdBy': data.createdBy || '',     
                        };
                        return mainPopinData;
                      }
                      return null;
                    }).filter(Boolean)} 
                    onRowClick={onRowClick} 
                    onCardClick={onCardClick} 
                    handleDeleteRow={handleDeleteRow} 
                    loading={loading}
                    callBackFunctionGetRowData={getRowData}
                    max={maxPopin}
                    searchFields={searchFields}
                  />
                    {(displayAlert?.open === true) ? <Alert {...displayAlert}/>: ""}
                    {(displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""}
                  </>
               </div>
            </div>
          </div>
      );
}

export default PopinComponent;