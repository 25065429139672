import { useState, useEffect, useRef } from 'react';
import TableChart from '../../../components/tables/TableUser';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import {Service_Api} from '../../../services/Api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {User} from '../../../services/object/User';
import {Salepoint} from '../../../services/object/Salepoint';
import {Role} from '../../../services/object/Role';
import {Univers} from '../../../services/object/Univers';
import { App } from '../../../services/object/App';
import { Job } from '../../../services/object/Job';
import { Law } from '../../../services/object/Law';
import { GridCallbackDetails, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import style from './Utilisateur.module.scss'
import ModalUser from './ModalUser'
import ModalFormComponent from '../../../components/modal/ModalFormComponent';
import Alert, { AlertProps } from '../../../components/alert/Alert';
import Notification, { NotificationProps } from '../../../components/notification/Notification';
import { displayFormErrors } from '../../../services/tools/errorForm';
import { fetchFavicon } from '../../../services/tools/fetchFavicon';
import React from 'react';

export interface UserData {
  user: User;
  salepoint: Salepoint[];
  univers: Univers[];
  role: Role[];
  job: Job;
  app : App[];
  law: Law[];
}

export interface ChildProps {
  uid: string;
  parentUid: string;
  value: boolean;
}

interface FetchAppResponse {
  success: boolean;
  data: App[];
}

function Utilisateur () {
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [users, setUsers] = useState<UserData[]>();
  const [formUsers, setFormUsers] = useState<User | undefined>(); 
  const [checkedRole, setCheckedRole] = useState<string[]>([]);
  const [checkedLaw, setCheckedLaw] = useState<string[]>([]);
  const [checkedNoJraApp, setCheckedNoJraApp] = useState<string[]>([]);
  const [checkedJraApp, setCheckedJraApp] = useState<string[]>([]);

  const [checkedSalepoint, setCheckedSalepoints] = useState<string[]>([]);
  const [checkedUnivers, setCheckedUnivers] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const [popupChildren, setPopupChildren] = useState<JSX.Element[]>();
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
  const [userData, setUserData] = useState<UserData>({user: {uid: "", jobUid: null, firstname: "",lastname: "",email: "",photo: "",position: ""}, salepoint: [], univers: [], role: [], job: {name : "", uid: ""}, app: [], law: []});
  const [appUser, setAppUser] = useState<App[]>([]);
  const [jraApps, setJraApps] = useState<App[]>([]);
  const [noJraApps, setNoJraApps] = useState<App[]>([]);

  const [job, setJob] = useState<Job[]>([]);
  const [T_O_salepoint, setSalepoint] = useState<Salepoint[]>([]);
  const [univers, setUnivers] = useState<string[]>([]); 
  const [childCheckedSalepoint, setChildCheckedSalepoint] = useState<ChildProps[]>([{ uid: "", parentUid: "", value: false },]);
  const [childCheckedLaw, setChildCheckedLaw] = useState<ChildProps[]>([{ uid: "", parentUid: "", value: false },]);
  const formRefUser = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const formRefJob = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const formRefApp = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const formRefRole = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const Api = Service_Api(); 
  const [displayAlert, setDisplayAlert] = useState<AlertProps>();
  const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
  const [lawAndRoleData, setLawAndRoleData] = useState<{ role: Role[]; law: Law[] }>({role: [], law: []});
  const [activeStep, setActiveStep] = useState<{ index: number, appUid: string }>();
  const [stepperData, setStepperData] = useState<App[]>();
  const [appWithLaw, setAppWithLaw] = useState<App[]>([]);

  const [maxUser, setMaxUser] = useState<number>(0);


  const getStepperData = (): App[] => {
    return checkedJraApp.map((appId: string) => {
      const selectedApp = jraApps.find((appItem: App) => appItem.uid === appId);
      const isInAppWithLaw = appWithLaw.find((appItem: App) => appItem.uid === appId);
      if (selectedApp && isInAppWithLaw) {
        let appTemp = {
          uid: appId,
          name: selectedApp ? selectedApp.name : '',
          url: selectedApp ? selectedApp.url : '',  
        } as App;
        if(selectedApp.url)
          appTemp.url = selectedApp.url
        return appTemp;
    } else {
      return null;
    }
    }).filter((app): app is App => app !== null);
  };

  useEffect(() => {
      fetchSalepoint();
      fetchPlate();
      fetchAllApp();
      fetchJob();
      fetchAppWithLaw();
      if(!isModalOpen) {
        setErrorMessages({});
      }
    }, [isModalOpen])

  useEffect(() => {
    if(activeStep?.appUid)
      fetchLawAndRoleByApp(activeStep.appUid);
  }, [activeStep])
 
  const fetchJob = async () => {
    try {
      const response = await Api.get('job/');
       setJob(response?.data); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };

  const fetchLawAndRoleByApp = async (appUid: string) => {
    try {
        const response = await Api.get('law/getLawAndRoleByApp', "appUid=" + appUid);
        if (response?.data) {
            const fetchedLawAndRoleData = response.data;
            if (response.success) {
              setLawAndRoleData({ role: fetchedLawAndRoleData.role, law: fetchedLawAndRoleData.law});
          }
        } else {
          setLawAndRoleData({role: [], law: []});
        }
    } catch (error) {
        console.error('Erreur lors de la récupération des données des lois et des rôles associés à l\'application', error);
    }
  };

  const fetchAppWithLaw = async () => {
    try {
      const response = await Api.get('app/getAppsWithLaws');
      setAppWithLaw(response?.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };

  const fetchSalepoint = async () => {
    try {
      const response = await Api.get('salepoint/');
      setSalepoint(response?.data); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };

  const fetchPlate = async () => {
    try {
      const response = await Api.get('univers/');
      setUnivers(response?.data); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };


  const fetchAllApp = async () => {
    try {
      const response = await Api.get('app/');
      let jraApps: App[] = [];
      let noJraApps: App[] = [];
      response?.data.forEach(async (app: App) => {
        app.url = await fetchFavicon(app.url);
        if (app.isJraApp === true) {
          jraApps.push(app);
        } else {
          noJraApps.push(app);
        }
      });
      setJraApps(jraApps);
      setNoJraApps(noJraApps); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };
  
  async function fetchAppByUser(userUid: string): Promise<FetchAppResponse> {
    try {
      const response = await Api.get('app/getAppsByUserUid', 'userUid='+userUid);
      setAppUser(response?.data);
      return { success: true, data: response?.data };
    } catch (error) {
      console.error('Erreur lors de la récupération des données', error);
      return { success: false, data: [] };
    }
  };
  
  const handleOpenModal = () => {
    setFormUsers({
      uid: '',
      jobUid: null,
      lastname: '',
      firstname: '',
      email: '',
      photo: '',
      position: '',
    });
    setCheckedRole([]);
    setCheckedLaw([]);
    setCheckedJraApp([]);
    setCheckedNoJraApp([]);
    setCheckedSalepoints([]);
    setCheckedUnivers([]);
    setIsNewUser(true);
    setIsModalOpen(true);
    setUserData({user: {uid: "",jobUid: "", firstname: "",lastname: "",email: "",photo: "",position: ""}, salepoint: [], univers: [], role: [], job: {name: "", uid: ""}, app: [], law: []});
    
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (stepperData) {
      setActiveStep(undefined);
    }
    setErrorMessages({});
    if (isNewUser) {
        handleDeleteUser();
    } 
  };

  
  
  
  const handleSubmitRoleAndLaw = async (): Promise<boolean> => {
    let userdatavar: UserData = userData;
    let uidsToPost: string[] = [];
    let uidsToDelete: string[] = [];
    let initialLaw = checkedLaw;
    let T_O_Role: Role[] = [...userData.role];
    let T_O_Role_New: Role[] = [];
    let T_roleChecked : {[key:string]:boolean} = {key:false};
    try {
        if (formUsers?.uid && userdatavar) {
          for (const child of childCheckedLaw) {
            if(typeof T_roleChecked[child.parentUid] === "undefined")
              T_roleChecked[child.parentUid] = true;

            if(!child.value)
              T_roleChecked[child.parentUid] = child.value

            if (child.value) {
              const parentPart = child.parentUid;
              if (parentPart.length >= 2) {
                const role: Role = 
                {
                  uid: child.parentUid,
                  //name undefined
                  name: "",
                  appUid: activeStep?.appUid ?? ""
                };
                T_O_Role_New.push(role);
              }
              uidsToPost.push(child.uid);
            } else {
              uidsToDelete.push(child.uid);
            }
          }
            let response;
            if(uidsToDelete.length > 0) {
              response = await Api.del('law/changeAsso', { Tuid: uidsToDelete, userUid: formUsers?.uid });
              if (!response?.success) {
                  return false;
              } else { 
                initialLaw = initialLaw.filter(id => !uidsToDelete.includes(id))
              }
            }
            if(uidsToPost.length > 0) {
              //dédoublonne les uid à poster
              T_O_Role_New = T_O_Role_New.filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.uid === value.uid && t.name === value.name && t.appUid === value.appUid
                ))
              );
              uidsToPost = uidsToPost.filter((value, index) => uidsToPost.indexOf(value) === index);
              response = await Api.post('law/changeAsso', { Tuid: uidsToPost, userUid: formUsers?.uid });
              if (!response?.success) {
                  return false;
              } else {
                  initialLaw = initialLaw.filter(id => !uidsToPost.includes(id));
                  initialLaw = initialLaw.concat(uidsToPost);

                  const uniqueRoles = new Set<string>();
                  T_O_Role = [...T_O_Role, ...T_O_Role_New].filter((role) => {
                    const roleKey = role.uid;
                    //La méthode has() renvoie un booléen qui indique s'il existe un élément de l'ensemble Set avec une certaine valeur.
                    if (uniqueRoles.has(roleKey)) {
                      return false;
                    } else {
                      //La méthode add() permet d'ajouter un nouvel élément ayant une valeur donnée à un ensemble Set. Cette valeur sera ajoutée à la fin de l'objet Set.
                      uniqueRoles.add(roleKey);
                      return true;
                    }
                  });
                  let updatedRoles = [];
                  for (let i = 0; i < T_O_Role.length; i++) {
                    let role = T_O_Role[i];
                    let matchingRole = T_O_Role_New.find(r => r.uid === role.uid);
                  
                    if (matchingRole && T_roleChecked[role.uid]) {
                      role = {
                        uid: role.uid,
                        name: role.name,
                        appUid: matchingRole.appUid
                      };
                    }
                    updatedRoles.push(role);
                  }
                  userdatavar.role = updatedRoles;
              }
            }
            //met à jour l'état des cases à cocher en ajoutant de nouveaux éléments (role)
            if (T_O_Role_New.length > 0) {
              let newRole = T_O_Role_New.filter(role => !checkedRole.includes(role.uid)).map(role => role.uid)
              newRole = newRole.concat(checkedRole)
              setCheckedRole(newRole);
            }
            
            setCheckedLaw(initialLaw);
            setUserData(userdatavar);
            return true;
        }else {
          return false;
        }    
    } catch (error) {
        console.error('Erreur lors de la soumission du formulaire', error);
        return false;
    }
  };

  const handleSubmitJraApp = async () => {
    try{
      if(formUsers?.uid) {
        let newData : UserData = userData;
        let response = await Api.post('app/changeAssoAllUid', {Tuid: checkedJraApp, userUid: formUsers?.uid});
        if (response?.success) {
          newData.app = response?.data
        }
          
        let appToDelete = jraApps.filter((app) => (!checkedJraApp.includes(app.uid))).map((app) => (app.uid))
        response = await Api.del('app/changeAssoAllUid', {Tuid: appToDelete, userUid: formUsers?.uid});
        if (response?.success) {
          newData.app = response?.data
          const lawsToUncheck = newData.law.filter(law => !checkedJraApp.includes(law.appUid)).map((law: Law)=>(law.uid));
          newData.law = newData.law.filter(law => checkedJraApp.includes(law.appUid));
          setCheckedLaw(newData.law.map((law: Law)=>(law.uid)));
          newData.role = newData.role.filter(role => checkedJraApp.includes(role.appUid));
          setCheckedRole(newData.role.map((role: Role)=>(role.uid)));
          if(lawsToUncheck.length)
            await Api.del('law/changeAsso', { Tuid: lawsToUncheck, userUid: formUsers?.uid });
        } 
        setUserData(newData)
        setAppUser(newData.app);
      }
    } catch (error) {
      console.log('Erreur lors de la soumission du formulaire', error);
    }
    return true;
  };

  const handleSubmitNoJraApp = async () => {
    try{
      if(formUsers?.uid) {
        let newData : UserData = userData
        let response = await Api.post('app/changeAssoAllUid', {Tuid: checkedNoJraApp, userUid: formUsers?.uid});
        if (response?.success) {
          newData.app = response?.data
        }
        
        let appToDelete = jraApps.filter((app) => (!checkedNoJraApp.includes(app.uid))).map((app) => (app.uid))
        response = await Api.del('app/changeAssoAllUid', {Tuid: appToDelete, userUid: formUsers?.uid});
        if (response?.success) {
          newData.app = response?.data
        }  
        setUserData(newData)
        setAppUser(newData.app);
      }
    } catch (error) {
      console.log('Erreur lors de la soumission du formulaire', error);
    }
    return true;
  };

 

  const handleSubmitSalepoint = async (): Promise<boolean> => {
    let userdatavar : UserData =  userData;
    let tableUnivers: Univers[] = [];
    let uidsToPost: string[] = [];
    let uidsToDelete: string[] = [];
    try {
      if (formUsers?.uid && userdatavar) {
        for (const child of childCheckedSalepoint ) {
          if (child.value) {
              uidsToPost.push(child.uid);
          } else {
              uidsToDelete.push(child.uid);
          }
        }
        let response = await Api.post('salepoint/changeAssoAllUid', {Tuid: uidsToPost, userUid: formUsers?.uid});
        let uniqueUnivers : Univers[] =[];
        if (response?.data.univers) {
            uniqueUnivers = tableUnivers.concat(response?.data.univers).filter((univers, index, self) => {
            return index === self.findIndex( element => element.uid === univers.uid );
          });
          tableUnivers = uniqueUnivers
        }
        userdatavar.salepoint  = response?.data.salepoint;
        userdatavar.univers = tableUnivers;
        if(uniqueUnivers.length > 0)
          setCheckedUnivers(uniqueUnivers.map((univers: Univers) => univers.uid));
        setCheckedSalepoints(uidsToPost);
        setUserData(userdatavar);
        response = await Api.del('salepoint/changeAssoAllUid', {Tuid: uidsToDelete , userUid: formUsers?.uid});
        if (!response?.success) {
          return false;
        }
        return true;
      } else{
        return false;
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire', error);
      return false;
    }
  };

  const handleSubmitUser = async () => {
    let userdatavar : UserData =  userData;
    try {
      if (formUsers) {
        const response = await Api.post('user/', formUsers);
        userdatavar.user = response?.data.user
        userdatavar.job = response?.data.job;
        setUserData(userdatavar);
        handleUserChange("uid", response?.data.user.uid);
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire');
    };
  };

  const handleUpdateUser = async () => {
    let userdatavar : UserData =  userData;
    try {
      const response = await Api.put(`user/`, formUsers);
      userdatavar.user = response?.data.user
      userdatavar.job = response?.data.job;
      setUserData(userdatavar);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'utilisateur', error);
    }
  };

  const handleDeleteUser = async () => {
    try {
      if (formUsers?.uid) {
        await Api.del(`user/`, {uid: formUsers?.uid});
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'utilisateur', error);
    }
  };

  const handleSaveUser = async (): Promise<boolean> => {
    let error = displayFormErrors(formRefUser);
    if(Object.keys(error).length === 0){
      try {
        if (formUsers?.uid) {
          await handleUpdateUser();
        } else {
          await handleSubmitUser();
        }
      } catch (error) {
        console.error('Erreur lors de la soumission ou de la mise à jour de l\'utilisateur', error);
      }
      return true;
    } else {
      setErrorMessages(error);
      return false;
    }
  };

  const handleSaveJobAndSalepoint = async (): Promise<boolean> => {
    let error = displayFormErrors(formRefJob);
    if (Object.keys(error).length === 0) {
      try {
        if (formUsers?.uid) {
          await handleUpdateUser(); 
          await handleSubmitSalepoint();
          const response = await fetchAppByUser(formUsers?.uid);
          if (response.success) {
            const userApps: App[] = response.data;
            if (userApps !== null) {
              let checkedAppIds;
              if (userApps && !isNewUser) {
                checkedAppIds = userApps.filter((appUser: App) => checkedJraApp.includes(appUser.uid)).map((appUser: App) => appUser.uid);
              } else {
                checkedAppIds = userApps.map((appUser: App) => appUser.uid);
              }
              setCheckedJraApp(checkedAppIds);
            }
          }
          return true;
        } else {
          return false;
        } 
      } catch (error) {
        console.error('Une erreur s\'est produite lors de la sauvegarde du métier et du point de vente:', error);
        return false;
      }
    } else {
      setErrorMessages(error);
      return false;
    }
  };

  const handleSaveApp = async (): Promise<boolean> => {
  let error = displayFormErrors(formRefApp);
    if (Object.keys(error).length === 0) {
        if (formUsers?.uid) {
          await handleSubmitNoJraApp();
          await handleSubmitJraApp();         
          const newCheckedApp = checkedJraApp.filter(appId => appWithLaw.some(app => app.uid === appId));
          // condition si role dans app
          await fetchLawAndRoleByApp(newCheckedApp[0]);
          setStepperData(getStepperData());
          setActiveStep({ index: 0, appUid: newCheckedApp[0] });
        }
        return true; 
    } else {
        setErrorMessages(error);
        return false;
    }
  };
  
  const handleSaveRoleAndLaw = async (): Promise<boolean> => {
    let error = displayFormErrors(formRefRole);
    if(Object.keys(error).length === 0){
      if(formUsers?.uid){
        await handleSubmitRoleAndLaw();
        return true;
      } else {
        return false;
      }
    } else {
      setErrorMessages(error);
      return false;
     }
  };

  const handleSave = async (): Promise<boolean> => { 
      if(formUsers?.uid){
        onFormSubmitSuccess(userData);
        setIsModalOpen(false);
        return true;
      } else {
        return false;
      }
  };

  const handleUserChange = (property: string, value: string) => {
    setFormUsers((prevFormUsers: any) => ({
      ...prevFormUsers,
      [property]: value,
    }));
  };  

  const getRowData = async (paginationModel: { page: number; pageSize: number;}, sort: {}, filter: {}) => {
    // fetch data from server
    setLoading(true)
    const response = await Api.post("user/getAllUserForDataGrid", {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sort,
        filter
      });
    if(response?.success){
        setMaxUser(response?.recordsTotal ?? 0)
        setUsers(response?.data);
        setLoading(false)

    }else{
        setUsers([]);
        setLoading(false)
    }
  };

  
  const onFormSubmitSuccess = (updatedData: UserData) =>{
    try {
        setUsers((prevUsers) => {
          if (prevUsers && Array.isArray(prevUsers)) {
            const validUsers = prevUsers.filter(user => user && user.user && user.user.uid);
            const userIndex = validUsers.findIndex((user) => user?.user?.uid === updatedData.user.uid);
            if(userIndex !== -1) {
              const updatedUsers = prevUsers?.map((user, index) =>
              index === userIndex ? { ...user, ...updatedData } : user
              );
            setDisplayNotif({
              open: true,
              contentText: "Les données utilisateur ont été mises à jour avec succès.",
              severity: "success",
              handleClose: () => setDisplayNotif(undefined),
            });
            return updatedUsers;
            } else {
              
              setDisplayNotif({
                open: true,
                contentText: "L'utilisateur a été enregistré avec succès.",
                severity: "success",
                handleClose: () => setDisplayNotif(undefined),
              });  
              return [updatedData, ...prevUsers];
            }
        }
        });
    } catch (error) {
      console.error('Erreur lors de la mise à jour des données utilisateur', error);
      setDisplayNotif({
        open: true,
        contentText: "Une erreur est survenue lors de la mise à jour des données utilisateur.",
        severity: "error",
        handleClose: () => setDisplayNotif(undefined),
      });
    }
  };
 

  const getUserDetails = async (userId: string) => {
    const response = await Api.get('user/', "uid="+userId);
    let newUserData = userData;
    if (response?.data.user)
      newUserData.user = response?.data.user;
    if (response?.data.role)
      newUserData.role = response?.data.role;
    if (response?.data.salepoint)
      newUserData.salepoint = response?.data.salepoint;
    if (response?.data.univers)
      newUserData.univers = response?.data.univers;
    if (response?.data.app)
      newUserData.app = response?.data.app;
    if (response?.data.law)
      newUserData.law = response?.data.law
    setUserData(newUserData);
    setFormUsers({
      uid: response?.data.user.uid,
      jobUid: response?.data.user.jobUid,
      lastname: response?.data.user.lastname,
      firstname: response?.data.user.firstname,
      email: response?.data.user.email,
      photo: response?.data.user.photo,
      position: response?.data.user.position
    });
    if (response?.data.role) {
      const userRole = response.data.role.map((role: Role) => role.uid);
      setCheckedRole(userRole);
    }

    if (response?.data.law) {
      setCheckedLaw(response.data.law.map((law: Law) => (law.uid)));
    }
    if (response?.data.salepoint) {
      const userSalepoints = response.data.salepoint.map((salepoint: Salepoint) => salepoint.uid);
      setCheckedSalepoints(userSalepoints);
    } 
    if (response?.data.univers) {
      const userUnivers = response.data.univers.map((univers: Univers) => univers.uid);
      setCheckedUnivers(userUnivers);
    } 
    if (response?.data.app) {
      const userJraApp = response.data.app.filter((app: App) => app.isJraApp === true).map((app: App) => app.uid);
      setCheckedJraApp(userJraApp);
    }
    if (response?.data.app) {
      const userNoJraApp = response.data.app.filter((app: App) => app.isJraApp === false).map((app: App) => app.uid);
      setCheckedNoJraApp(userNoJraApp);
    }
    setIsNewUser(false);
  }

  const onRowClick = (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
    const userId = params.id as string;
    getUserDetails(userId);
    setIsModalOpen(true);
  };

  const onCardClick = (userId: string) => {
    getUserDetails(userId);
    setIsModalOpen(true);
  }

  const handleDeleteRow = async (userId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setDisplayAlert({
      open: true,
      title: "Suppression",
      contentText: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
      contentButtonSuccess: "Oui",
      contentButtonAbord: "Non",
      onSubmitSuccess: async () => {
        try {
          await Api.del("user/", { uid: userId });
  
          const updatedUsers = users?.filter((data: UserData) => data.user.uid !== userId);
          setUsers(updatedUsers);
  
          setDisplayNotif({
            open: true,
            contentText: "L'utilisateur a été supprimé",
            severity: "success",
            handleClose: () => setDisplayNotif(undefined),
          });
        } catch (error) {
          console.error("Erreur lors de la suppression de l'utilisateur", error);
          setDisplayNotif({
            open: true,
            contentText: "Aïe, une erreur est survenue lors de la suppression de l'utilisateur.",
            severity: "error",
            handleClose: () => setDisplayNotif(undefined),
          });
        } finally {
          setDisplayAlert(undefined);
        }
      },
      onSubmitAbord: () => setDisplayAlert(undefined),
    });
  }; 

  const columns = [
    { field: 'JRA_user.firstname', headerName: 'Prénom', flex: 0.1, minWidth:  130 },
    { field: 'JRA_user.lastname', headerName: 'Nom', flex: 0.1, minWidth:  130 },
    { field: 'JRA_user.email', headerName: 'Email', flex: 0.19, minWidth: 120 },
    { field: 'JRA_job.name', headerName: 'Métier', flex: 0.19, minWidth:  130 },
    { field: 'JRA_univers.name', headerName: 'Plaque', flex: 0.19, minWidth: 90 },
    { field: 'JRA_salepoint.name', headerName: 'Concession', flex: 0.19, minWidth: 90 },
    {
      field: 'actions',
      headerName: '',
      width: 90,
      renderCell: (params: any) => (
        <div>
          <IconButton>
            <EditIcon color="primary" />
          </IconButton>
          <IconButton onClick={(event) => handleDeleteRow(params.row.id, event)}>
            <DeleteIcon color="primary" />
          </IconButton>
        </div>
      ),
    },
  ]; 

  useEffect(() => {
    let children = ModalUser({   
      childCheckedLaw,
      setChildCheckedLaw,
      errorMessages,
      handleUserChange,
      formUsers,
      checkedRole,
      setChildCheckedSalepoint,
      checkedSalepoint,
      checkedUnivers,
      checkedLaw,
      formRefUser,
      formRefJob,
      formRefApp,
      formRefRole,
      job,
      jraApps,
      noJraApps,
      univers,
      T_O_salepoint,
      childCheckedSalepoint,
      setCheckedJraApp,
      checkedJraApp,
      setCheckedNoJraApp,
      checkedNoJraApp,
      lawAndRoleData,
      activeStep,
      setActiveStep,
      handleSaveRoleAndLaw,
      stepperData,
      userData,
    });
    setPopupChildren(children)
  }, [formUsers, checkedRole, checkedJraApp, checkedNoJraApp, childCheckedSalepoint, childCheckedLaw, checkedSalepoint, checkedLaw, errorMessages, lawAndRoleData, activeStep]);

  let formEvents = [handleSaveUser, handleSaveJobAndSalepoint, handleSaveApp, handleSaveRoleAndLaw, handleSave];
  let subtitles = ['1/3 - Renseigner les infos principales', '2/3 - Définir le métier , marques et/ou concessions', '3/3 - Gérer l’accès aux app', '3/3 - Gérer l’accès aux app', 'Récapitulatif'];

  if (lawAndRoleData.role.length <= 0 && lawAndRoleData.law.length <= 0) {
    formEvents.splice(3, 1);
    subtitles.splice(3, 1);
  }

  const searchFields = [
    'JRA_user.firstname',
    'JRA_user.lastname',
    'JRA_user.email',
    'JRA_job.name',
    'JRA_univers.name',
    'JRA_salepoint.name'
  ];

  return (
      <div className='background'>
        <h1>Gérer les utilisateurs</h1>
        <div className='backsquare'>
          <div className={'tableContainer ' + style.userContainer}>
            <div className={style.lineContain}>
              <Button  className={style.addbutton} variant="contained" onClick={handleOpenModal} endIcon={<AddIcon/>}>
                Nouvel utilisateur 
              </Button>
            </div>
              <ModalFormComponent
                title={isNewUser ? "Nouvel Utilisateur" : "Modification de l'utilisateur"}
                subtitle={subtitles}
                modalOpen={isModalOpen} 
                onClose={handleCloseModal} 
                onFormSubmitSuccess={handleSave} 
                childrenForm={popupChildren}
                formEvent={formEvents} 
                stepperLength={stepperData?.length}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setErrorMessages={setErrorMessages}
                alternativeStyle={style}
                />
              <TableChart 
                  columns={columns} 
                  dataRow={users?.map((data: UserData) => {
                    if (data && data.user) {
                      const mainUserData = {
                        id: data.user.uid,
                        'JRA_user.firstname': data.user.firstname,
                        'JRA_user.lastname': data.user.lastname,
                        'JRA_user.email': data.user.email,
                        'JRA_job.name': (data.job) ? data.job.name : "",
                        'JRA_univers.name': (data.univers && data.univers.length) ? data.univers.map((plate: any) => plate.name).join(', ') : "",
                        'JRA_salepoint.name': (data.salepoint && data.salepoint.length) ? data.salepoint.map((sale: any) => sale.name) : "",
                      };
                      return mainUserData;
                      
                    }
                    return null;
                  }).filter(Boolean)} 
                  onRowClick={onRowClick} 
                  onCardClick={onCardClick} 
                  handleDeleteRow={handleDeleteRow} 
                  loading={loading}
                  callBackFunctionGetRowData={getRowData}
                  max={maxUser}
                  searchFields={searchFields}
                />
              {(displayAlert?.open === true) ? <Alert {...displayAlert}/>: ""}
              {(displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""}
          </div>
        </div>
      </div>
  );
};

export default Utilisateur;