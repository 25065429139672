import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../App.scss";
import style from "./assets/scss/accueil.module.scss";

import { Service_Api } from "../../services/Api";
import { useAuth } from "../../services/auth/useAuth";
import { Post } from "../../services/object/Post";
import { SalepointData } from "../../services/object/Salepoint";
import { jsDateToLocalFr } from "../../services/tools/translateDate";
import { truncateText } from "../../services/tools/truncateText";
import { formatWithLineBreaks } from "../../services/tools/formatWithLineBreak"

import ModalSuggestedPost from "./ModalSuggestedPost";

import ModalFormComponent from "../../components/modal/ModalFormComponent";
import Notification, {
  NotificationProps,
  renderNotif,
} from "../../components/notification/Notification";
import { Loader } from "../../components/loader/Loader";
import Alert, { AlertProps } from "../../components/alert/Alert";

import noPhotoPost from "./assets/images/noPhotoPost.png";
import youtubeLogo from "./assets/images/youtubeLogo.png";

import AddIcon from "@mui/icons-material/Add";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useLaw } from "../../services/auth/useLaw";
import { newsConfig } from "../../services/object/Law";

function AllPosts() {
  const Api = Service_Api();
  const location = useLocation();
  const { user } = useAuth();
  const { law } = useLaw();

  const [loader, setLoader] = useState<boolean>(true);
  const [TOPost, setTOPost] = useState<Post[]>([]);
  const [expandedPosts, setExpandedPosts] = useState<{
    [key: number]: boolean;
  }>({});
  const tocRef = useRef<HTMLUListElement>(null);
  const autoScrollRef = useRef<HTMLDivElement>(null);

  const [likedPosts, setLikedPosts] = useState<string[]>([]);

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRefPost =
    useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const [formPost, setFormPost] = useState<Post | undefined>();
  const [modalPostComponent, setModalPostComponent] = useState<JSX.Element[]>();
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>(
    {}
  );
  const [displayAlert, setDisplayAlert] = useState<AlertProps>();
  const [notif, setNotif] = useState<NotificationProps>();

  const monthOrder = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  // fetch all posts + fetch liked posts of current user
  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        await fetchAllPosts();
        setLoader(false);
        const response = await Api.get(
          "post/getLikedPosts",
          `userUid=${user.uid}`
        );
        setLikedPosts(response?.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
      }
    };

    fetchData();
  }, []);
  // set active menu
  useEffect(() => {
    const setActiveItems = (index: number) => {
      const tocItems = tocRef.current?.querySelectorAll("li a");
      tocItems?.forEach((item, i) => {
        if (i < index) {
          item.classList.add(style.active);
        } else {
          item.classList.remove(style.active);
        }
      });
    };

    const handleScroll = () => {
      const postElements = document.querySelectorAll(`.${style.post}`);
      let activeIndex = -1;

      postElements.forEach((post, index) => {
        const rect = post.getBoundingClientRect();
        const autoScrollRect = autoScrollRef.current?.getBoundingClientRect();

        if (
          autoScrollRect &&
          rect.top >= autoScrollRect.top &&
          rect.top <= autoScrollRect.bottom / 2
        ) {
          activeIndex = index;
        }
      });

      if (activeIndex !== -1) {
        setActiveItems(activeIndex);
      }
    };

    const autoScrollElement = autoScrollRef.current;
    autoScrollElement?.addEventListener("scroll", handleScroll);

    // Initial call to set active item on page load
    handleScroll();

    return () => {
      autoScrollElement?.removeEventListener("scroll", handleScroll);
    };
  }, [TOPost]);
  // set modal default data
  useEffect(() => {
    let children = ModalSuggestedPost({
      errorMessages,
      handlePostChange,
      formPost,
      formRefPost,
    });
    setModalPostComponent(children);
  }, [formPost, errorMessages]);
  // set anchor if redirection from home
  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.substring(1);

      const scrollToElement = () => {
        const element = document.getElementById(targetId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          const index = TOPost.findIndex((post) => `post-${post.uid}` === targetId);
          if (index !== -1) {
            setExpandedPosts((prev) => ({ ...prev, [index]: true }));
          }
        } else {
          requestAnimationFrame(scrollToElement);
        }
      };
      scrollToElement();
    }
  }, [location, TOPost]);
  // open modal if click suggest post from home
  useEffect(() => {
    if (window.location.hash === "#open-modal") {
      onOpenModalClick();
    }
  }, []);


  // fetch
  const fetchAllPosts = async () => {
    try {
      const response = await Api.get("post/");
      const posts = response?.data;
      const publishedPosts = posts.filter((post: any) => post.isDraft === false);
      const sortedPosts = publishedPosts.sort((a: any, b: any) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });

      setTOPost(sortedPosts);
      console.log("Données récupérées avec succès", sortedPosts);
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    }
  };

  const extractYouTubeId = (url: string): string => {
    const regExp =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=)|youtu\.be\/)([^#&?\/\n]+)(?:\S+)?/;
    const match = url.match(regExp);
    return match ? match[1] : "";
  };
  const getYouTubeThumbnailUrl = (url: string): string => {
    const videoId = extractYouTubeId(url);
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  // render
  const renderPostForList = (post: Post, index: number) => {
    if (post.description) {
      const isExpanded = expandedPosts[index];
      const truncatedDescription = truncateText(post.description, 200);
      let isLiked = false;
      if (likedPosts) {
        isLiked = likedPosts.includes(post.uid);
      }

      return (
        <div id={`post-${post.uid}`} className={style.post} key={index}>
          <div className={style.postContent}>
            <div className={style.header}>
              {post.postCategoryId === 2 ? (
                <h3>
                  Bienvenue {post.title} {post.subtitle}
                </h3>
              ) : (
                <h3>{post.title}</h3>
              )}
              <div className={style.tagsBox}>
                <span className={style.date}>
                  {post.date &&
                    jsDateToLocalFr(new Date(post.date))}
                </span>
              </div>
            </div>
            <div className={style.postBody}>
              <div className={style.imageBox}>
                <div className={style.likeNumberBox}>
                  <div
                    className={`${style.likeButton} ${isLiked ? style.isActive : ""
                      }`}
                    onClick={() => onLikeClick(post.uid)}
                  >
                    <ThumbUpIcon
                      className={`${style.isLiked} ${style.bouncy}`}
                    />
                    <ThumbUpOffAltIcon
                      className={`${style.notLiked} ${style.bouncy}`}
                    />
                    <span className={style.likeOverlay}></span>
                  </div>
                  <span className={style.likeNumber}>{post.likeNumber}</span>
                </div>

                {post.urlYoutube ? (
                  <div className={style.youtubeBox}>
                    <img
                      src={youtubeLogo}
                      className={`${style.youtubeLogo} ${isExpanded ? style.expanded : ""}`}
                    />
                    {!isExpanded ? (
                      <img
                        src={getYouTubeThumbnailUrl(post.urlYoutube)}
                        alt="YouTube video thumbnail"
                        className={`${isExpanded ? style.expanded : ""}`}
                      />
                    ) : (
                      <iframe
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${extractYouTubeId(
                          post.urlYoutube
                        )}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className={`${isExpanded ? style.expanded : ""}`}
                      ></iframe>
                    )}
                  </div>
                ) : (
                  <img
                    src={post.photo ? post.photo : noPhotoPost}
                    alt="actualité"
                    className={`${isExpanded ? style.expanded : ""}`}
                  />
                )}
              </div>
              <div
                className={`
                  ${style.postText} 
                  ${style.backgroundBlur} 
                  ${isExpanded ? style.expanded : ""}
                `}
              >
                {post.subtitle && post.postCategoryId === 1 ? (
                  <h4>{post.subtitle}</h4>
                ) : (
                  ""
                )}
                <p>
                  {isExpanded ? (
                    formatWithLineBreaks(post.description)
                  ) : (
                    formatWithLineBreaks(truncatedDescription)
                  )}
                </p>
                <div className={`buttonBox ${style.buttonBox}`}>
                  {isExpanded && post.url && (
                    <Link
                      to={post.url}
                      className={`button smallButton ${style.button} ${style.outsideLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Plus d'infos
                      <OpenInNewIcon />
                    </Link>
                  )}

                  <Link
                    to={"https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&" + ((post.url) ? "shareUrl=" + post.url : "") + "&text=" + post.description}
                    className={`button smallButton ${style.button} ${style.outsideLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Partager sur Linkedin
                    <LinkedInIcon />
                  </Link>
                  <p
                    onClick={() => onToggleDescription(index)}
                    className={`button smallButton ${style.button}`}
                  >
                    {isExpanded ? "Voir moins" : "Voir plus"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    }
  };
  const renderPostsList = () => {
    if (TOPost.length > 0) {
      return (
        <div className={style.list}>{TOPost.map(renderPostForList)}</div>
      );
    } else {
      return <p>Aucune actualité</p>;
    }
  };
  const renderTableOfContents = () => {
    if (TOPost.length === 0) {
      return <p>Aucune actualité</p>;
    }

    const parseDate = (date: Date | string): Date => {
      return typeof date === "string" ? new Date(date) : date;
    };

    // Regroupement des posts par mois
    const groupedPosts = TOPost.reduce(
      (acc: { [key: string]: Post[] }, post) => {
        const date = parseDate(post.date);
        const monthName = date.toLocaleString("fr-FR", { month: "long" });
        if (!acc[monthName]) {
          acc[monthName] = [];
        }
        acc[monthName].push(post);
        return acc;
      },
      {}
    );
    // Tri des mois selon l'ordre dans l'année
    const sortedMonths = Object.keys(groupedPosts)
      .sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b))
      .reverse();

    return (
      <ul ref={tocRef} className={style.tocList}>
        {sortedMonths.map((month) => (
          <li key={month}>
            <strong>{month.charAt(0).toUpperCase() + month.slice(1)}</strong>{" "}
            <ul>
              {groupedPosts[month].map((post, index) => (
                <li key={index}>
                  <a href={`#post-${post.uid}`}>
                    {post.postCategoryId === 2
                      ? `Bienvenue ${post.title} ${post.subtitle}`
                      : truncateText(post.title, 50)}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    );
  };

  // actions
  const onToggleDescription = (index: number) => {
    setExpandedPosts((prev) => {
      const isExpanded = !prev[index];

      if (isExpanded) {
        // Si on va ouvrir le post, on scroll vers le haut du post
        const postElement = document.getElementById(`post-${TOPost[index].uid}`);
        postElement?.scrollIntoView({ behavior: "smooth", block: "start" });
      }

      return { ...prev, [index]: isExpanded };
    });
  };
  const onLikeClick = async (uid: string) => {
    try {
      let updatedPosts = [...TOPost];
      const postIndex = updatedPosts.findIndex((post) => post.uid === uid);

      if (likedPosts && likedPosts.includes(uid)) {
        await Api.del("post/changeAssoLike", {
          userUid: user.uid,
          postUid: uid,
        });
        setLikedPosts((prevLikedPosts) =>
          prevLikedPosts.filter((postUid) => postUid !== uid)
        );

        if (postIndex !== -1) {
          updatedPosts[postIndex].likeNumber -= 1;
        }
      } else {
        await Api.post("post/changeAssoLike", {
          userUid: user.uid,
          postUid: uid,
        });
        setLikedPosts((prevLikedPosts) => [...(prevLikedPosts || []), uid]);

        if (postIndex !== -1) {
          updatedPosts[postIndex].likeNumber += 1;
        }
      }

      setTOPost(updatedPosts);
    } catch (error) {
      console.error("Erreur lors du traitement du like", error);
    }
  };
  const onOpenModalClick = () => {
    let formData = {
      uid: "",
      title: "",
      subtitle: "",
      description: "",
      url: "",
      urlYoutube: "",
      photo: "",
      newEmployeeJob: "",
      univers: {
        uid: "",
        name: ""
      },
      salepoint: {
        uid: "",
        name: ""
      },
      postCategoryId: 0,
      isImportantPost: false,
      likeNumber: 0,
      isDraft: false,
      isSuggestedPost: false,
      createdBy: null,
      date: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    };

    setFormPost(formData);
    setIsModalOpen(true);
  };
  const onCloseModalClick = async () => {
    setFormPost({
      uid: "",
      title: "",
      subtitle: "",
      description: "",
      url: "",
      urlYoutube: "",
      photo: "",
      newEmployeeJob: "",
      univers: {
        uid: "",
        name: ""
      },
      salepoint: {
        uid: "",
        name: ""
      },
      postCategoryId: 0,
      isImportantPost: false,
      likeNumber: 0,
      isDraft: false,
      isSuggestedPost: false,
      createdBy: null,
      date: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    });

    setIsModalOpen(false);
    setErrorMessages({});
  };
  const onPostSaveClick = async () => {
    if (handleFormErrors()) {
      try {
        console.log(formPost);
        await handleSuggestedPost();
      } catch (error) {
        console.error("Erreur lors de la soumission du post", error);
      }
      return true;
    } else {
      return false;
    }
  };

  // Process datas after actions
  const handleSuggestedPost = async () => {
    setDisplayAlert({
      open: true,
      title: "Soumettre",
      contentText:
        "Êtes-vous sûr de vouloir soumettre cette actualité ? Elle sera envoyée à un administrateur pour validation avant publication.",
      contentButtonSuccess: "Oui",
      contentButtonAbord: "Non",
      onSubmitSuccess: async () => {
        try {
          if (formPost) {
            const actualFormPost = {
              ...formPost,
              isDraft: true,
              isSuggestedPost: 1,
              postCategoryId: 1,
            };
            const response = await Api.post("post/", actualFormPost);
            handlePostChange("uid", response?.data.uid);
            console.log("Formulaire soumis avec succès", response);
            onCloseModalClick();
            setNotif(renderNotif(true, "suggest", handleCloseNotif));
          }
        } catch (error) {
          console.error("Erreur lors de la soumission du formulaire");
        } finally {
          setDisplayAlert(undefined);
        }
      },
      onSubmitAbord: () => setDisplayAlert(undefined),
    });
  };
  const handlePostChange = (
    property: string,
    value: string | number | SalepointData | null
  ) => {
    console.log(property, value);

    setFormPost((prevFormPosts: any) => ({
      ...prevFormPosts,
      [property]: value,
    }));
  };
  const handleCloseNotif = () => {
    setNotif(undefined);
  };
  const handleFormErrors = () => {
    const errors: Record<string, string> = {};

    const requiredString = "Requis";
    const invalidFormatString = "Format invalide";
    const lengthExceededString = "Longueur maximale dépassée";
    const noEmoticonsAllowedString = "Les émoticônes ne sont pas autorisées";

    const adjustAndValidateUrl = (url: string | null): string | null => {
      if (!url) return null;

      if (!/^https?:\/\//i.test(url)) {
        url = "https://" + url;
      }

      try {
        const parsedUrl = new URL(url);
        if (parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:") {
          return url;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    };

    const hasNoEmoticons = (text: string | null): boolean => {
      if (!text) return true;
      const regex = /[\p{Emoji_Presentation}|\p{Extended_Pictographic}]/u;

      return !regex.test(text);
    };

    if (formPost) {
      if (!formPost.title) {
        errors.title = requiredString;
      } else {
        if (formPost.title.length > 100) {
          errors.title = lengthExceededString;
        }
        if (!hasNoEmoticons(formPost.title)) {
          errors.title = noEmoticonsAllowedString;
        }
      }

      if (!formPost.description) {
        errors.description = requiredString;
      } else {
        if (formPost.description.length > 1000) {
          errors.description = lengthExceededString;
        }
        if (!hasNoEmoticons(formPost.description)) {
          errors.description = noEmoticonsAllowedString;
        }
      }

      if (formPost.postCategoryId == 2) {
        if (!formPost.subtitle) {
          errors.subtitle = requiredString;
        } else {
          if (formPost.subtitle.length > 255) {
            errors.subtitle = lengthExceededString;
          }
          if (!hasNoEmoticons(formPost.subtitle)) {
            errors.subtitle = noEmoticonsAllowedString;
          }
        }

        if (!formPost.photo) {
          errors.photo = requiredString;
        }
      }

      if (formPost.url) {
        formPost.url = adjustAndValidateUrl(formPost.url);
        if (!formPost.url) {
          errors.url = invalidFormatString;
        }
      }

      if (formPost.urlYoutube) {
        formPost.urlYoutube = adjustAndValidateUrl(formPost.urlYoutube);
        if (!formPost.urlYoutube) {
          errors.urlYoutube = invalidFormatString;
        }
      }

      setErrorMessages(errors);
    }
    return Object.keys(errors).length === 0;
  };


  return loader ? (
    <Loader />
  ) : (
    <div id={style.allPostsPage} className="background">
      <div className={style.content}>
        <h1>Toutes les actualités</h1>
        <div>
          <div id={style.tableOfContents}>{renderTableOfContents()}</div>
          <div className={style.block}>
            <div className={`buttonBox ${style.buttonBox}`}>
              <p
                className={`button ${style.button}`}
                onClick={() => onOpenModalClick()}
              >
                Soumettre une actualité
                <AddIcon />
              </p>
              {
                (law.filter((law) => (law.uid === newsConfig)).length === 1) ?

                  <Link to="/accueilConfig" className={`button ${style.button}`}>
                    Gérer les actualités
                    <ExitToAppIcon />
                  </Link>

                  : ""
              }

            </div>
            <div
              className={`autoScroll ${style.autoScroll}`}
              ref={autoScrollRef}
            >
              {renderPostsList()}
            </div>
          </div>
        </div>
      </div>

      {displayAlert?.open === true ? <Alert {...displayAlert} /> : ""}

      {notif?.open === true ? <Notification {...notif} /> : ""}

      {
        <ModalFormComponent
          title="Soumettre une actualité"
          modalOpen={isModalOpen}
          onClose={onCloseModalClick}
          onFormSubmitSuccess={onPostSaveClick}
          childrenForm={modalPostComponent}
          formEvent={[onPostSaveClick]}
          setErrorMessages={setErrorMessages}
          modalType="postSuggestion"
        />
      }
    </div>
  );
}

export default AllPosts;
