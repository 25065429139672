import React from 'react';
import style from './law.module.scss';

import { App } from '../../../services/object/App';
import { Job } from '../../../services/object/Job';
import { Univers } from '../../../services/object/Univers';
import { Salepoint } from '../../../services/object/Salepoint';

import FormApp from './FormApp'

interface AppPopupChildrensProps {
  errorMessages: Record<string, string>,
  formRefApp: React.RefObject<HTMLFormElement>,
  app: App | undefined,
  handleFormAppChange: (property: string, value: string) => void
  ToJob: Job[]
  arrayJobLabel: [string[]] | undefined
  arrayUniversLabel: [string[]] | undefined
  TOSalepoint: Salepoint[]
  arraySalepointLabel: [string[]] | undefined
  setArraySalepointLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  setArrayUniversLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  setArrayJobLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  TOUnivers: Univers[]
  setExceptionNumber: React.Dispatch<React.SetStateAction<number>>
  exceptionNumber: number
}

const AppPopupChildrens = ({
  errorMessages,
  formRefApp,
  app,
  ToJob,
  arrayJobLabel,
  TOSalepoint,
  arraySalepointLabel,
  setArraySalepointLabel,
  setArrayJobLabel,
  TOUnivers,
  arrayUniversLabel,
  setArrayUniversLabel,
  handleFormAppChange,
  setExceptionNumber,
  exceptionNumber
}: AppPopupChildrensProps) => {

  console.log('AppPopupChildrens app:', app)

  return ([
    <form className={style.uiform} ref={formRefApp}>
        <FormApp
          errorMessages={errorMessages}
          app={app}
          ToJob={ToJob}
          arrayJobLabel={arrayJobLabel}
          TOSalepoint={TOSalepoint}
          arraySalepointLabel={arraySalepointLabel}
          setArraySalepointLabel={setArraySalepointLabel}
          setArrayJobLabel={setArrayJobLabel}
          TOUnivers={TOUnivers}
          arrayUniversLabel={arrayUniversLabel}
          setArrayUniversLabel={setArrayUniversLabel}
          handleFormAppChange={handleFormAppChange}
          setExceptionNumber={setExceptionNumber}
          exceptionNumber={exceptionNumber}
        />
    </form>
  ])
}
export default AppPopupChildrens;