import style from './law.module.scss';
import styleModal from '../../components/modal/modal.module.scss';
import { Autocomplete, Checkbox, FormControl, FormHelperText, TextField } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import React, { SyntheticEvent } from 'react';
import { Law } from '../../../services/object/Law';
import { Salepoint } from '../../../services/object/Salepoint';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Job } from '../../../services/object/Job';
import ControllableStates from '../../../components/input/ControllableStates';
import { App } from '../../../services/object/App';
import { Role } from '../../../services/object/Role';

interface LawPopupChildrensProps {
    errorMessages: Record<string, string>, 
    formRefLaw: React.RefObject<HTMLFormElement>, 
    law: Law| undefined, 
    jobLabel: String[], 
    setJobLabel: React.Dispatch<React.SetStateAction<string[]>>
    roleLabel: String[], 
    setRoleLabel: React.Dispatch<React.SetStateAction<string[]>>
    ToJob: Job[], 
    handleFormLawChange: (property: string, value: string) => void
    TOapp: App[]
    ToRole: Role[]

  }
    const lawPopupChildrens = ({
                                        errorMessages, 
                                        formRefLaw, 
                                        law, 
                                        ToJob,
                                        jobLabel,
                                        setJobLabel,
                                        handleFormLawChange,
                                        TOapp,
                                        ToRole,
                                        roleLabel,
                                        setRoleLabel} :LawPopupChildrensProps) => {

    const handleChangeJob= (event: SyntheticEvent<Element, Event>, value: Job[]) => {
        let Tjob = ToJob.filter((job : Job) => { 
            return value.includes(job)
        })
        setJobLabel(Tjob.map((job :Job) => {return job.name}))
    };
         
    
    const handleChangeRole= (event: SyntheticEvent<Element, Event>, value: Role[]) => {
        let Trole = ToRole.filter((role : Role) => { 
            return value.includes(role)
        })
        setRoleLabel(Trole.map((role :Role) => {return role.name}))
    };
         
    const onChangeApp = (appUid: string) => {
        handleFormLawChange("appUid", appUid)
    };
                               
    return ([
            <form className={style.uiform} ref={formRefLaw}>
                <div className={style.contentText}>
                    <TextField fullWidth label="Nom"  
                        size="medium"
                        value={law?.name}
                        name="name"
                        onChange={(e) => handleFormLawChange('name', e.target.value)} required
                        error={!!errorMessages.name}
                        helperText={errorMessages.name}/>
                    <ControllableStates
                        Objet={TOapp.filter((app)=>(app.isJraApp))}
                        legend="Application liée"
                        onChange={onChangeApp}
                        selecteUid={law?.appUid ?? ""}
                        errorMessages={errorMessages}
                        />
                    <FormControl >
                        <Autocomplete
                            title={jobLabel.join(', ')}
                            multiple
                            className={style.autocomplete}
                            limitTags={2}
                            options={ToJob}
                            disableCloseOnSelect
                            getOptionLabel={(job :Job) => job.name}
                            onChange={handleChangeJob}
                            renderOption={(props, job, { selected = jobLabel.indexOf(job.name) > -1 }) => {
                                const { ...optionProps } = props;
                                return (
                                <li  {...optionProps}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        value={job.name}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={jobLabel.indexOf(job.name) > -1 || selected}
                                        required={(jobLabel.length === 0)}
                                        />
                                    {job.name}
                                </li>
                                );
                            }}
                            value={ToJob.filter((job:Job)=>{
                                if(jobLabel.indexOf(job.name) > -1) {
                                    return job 
                                 }
                            })}
                            renderInput={ params => {
                                const { InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;
                                return (
                                  <TextField
                                    required={(jobLabel.length === 0)}
                                    name='job'
                                    error={!!errorMessages.job}
                                    { ...restParams }
                                    label="Métiers(s) concerné(s)"
                                    InputProps={ {
                                      ...restInputProps,
                                      startAdornment: (
                                        <div className={style.scrollDiv}>
                                          {startAdornment}
                                        </div>
                                      ),
                                    } }
                                  />
                                );
                              } }
                            />
                        <FormHelperText className='error'>{errorMessages.job}</FormHelperText>
                    </FormControl>
                    
                    <FormControl >
                        <Autocomplete
                            title={roleLabel.join(', ')}
                            multiple
                            className={style.autocomplete}
                            limitTags={2}
                            options={ToRole.filter((role: Role) => {return role.appUid === law?.appUid})}
                            disableCloseOnSelect
                            getOptionLabel={(role :Role) => role.name}
                            onChange={handleChangeRole}
                            renderOption={(props, role, { selected = roleLabel.indexOf(role.name) > -1 }) => {
                                const { ...optionProps } = props;
                                return (
                                <li  {...optionProps}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        value={role.name}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={roleLabel.indexOf(role.name) > -1 || selected}
                                        required={(roleLabel.length === 0)}
                                        />
                                    {role.name}
                                </li>
                                );
                            }}
                            value={ToRole.filter((role: Role) => {return role.appUid === law?.appUid}).filter((role:Role)=>{
                                if(roleLabel.indexOf(role.name) > -1) {
                                    return role 
                                 }
                            })}
                            renderInput={ params => {
                                const { InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;
                                return (
                                  <TextField
                                    required={(roleLabel.length === 0)}
                                    name='role'
                                    error={!!errorMessages.role}
                                    { ...restParams }
                                    label="Role(s) concerné(s)"
                                    InputProps={ {
                                      ...restInputProps,
                                      startAdornment: (
                                        <div className={style.scrollDiv}>
                                          {startAdornment}
                                        </div>
                                      ),
                                    } }
                                  />
                                );
                              } }
                            />
                        <FormHelperText className='error'>{errorMessages.role}</FormHelperText>
                    </FormControl>
                </div>
                <div className={style.textAreaContenair}>
                    <BaseTextareaAutosize name="description" placeholder="Description" value={law?.description} onChange={(e) => handleFormLawChange('description', e.target.value)}></BaseTextareaAutosize>
                </div>
            </form>
    ])
}
export default lawPopupChildrens;